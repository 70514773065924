import { useTheme } from "@emotion/react";
import { Box } from "@mui/material";
import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import TableCustomSelect from "src/helper/Table/TableCustomSelect";
import { qcManagementSlice } from "src/store/slice/qcManagement";
import { QCManagementContext } from "src/views/QCManagement/QCManagementRoute";

const qcDataColumns = [
  {
    id: "id",
    label: "Data ID",
    styles: { maxWidth: "150px", width: "auto" },
    align: "center",
  },
  {
    id: "first_name",
    label: "First Name",
    align: "left",
  },

  {
    id: "erroe_content_data",
    label: "Error Content Data",
    // styles: { maxWidth: "120px", width: "120px" },
    align: "left",
    sort: false,
  },
  {
    label: "Actions",
    align: "center",
    styles: { maxWidth: "160px", width: "160px" },
  },
];

const QCDataSmartCheckTable = ({ page, dataCount, dataPerPage }) => {
  const [selected, setSelected] = useState([]);
  const [rowData, setRowData] = useState([]);

  return (
    <div>
      <Box
        sx={{
          fontWeight: "bold",
          fontSize: "18px",
          textDecoration: "underline",
        }}
      >
        QC Data List View
      </Box>
      <Box sx={{ border: "1px #ccc solid", marginTop: "10px" }}>
        <TableCustomSelect
          rowData={rowData}
          editAction={(row) => {
            //   qcContext.openViewQCDialog(row.id.user_id);
            //   dispatch(actions.userFetched(row));
          }}
          show={true}
          showViewButton={true}
          selectedCheckbox={selected}
          setSelectedCheckbox={setSelected}
          showEditButton={false}
          showDeleteButton={false}
          showMoreButton={false}
          page={page}
          dataCount={dataCount}
          dataPerPage={dataPerPage}
          // handleRequestSort={(currentKey, order) => {
          //   const isAsc = order === "asc";
          //   dispatch(
          //     actions.setSortConfig({
          //       key: currentKey,
          //       order: isAsc ? "desc" : "asc",
          //     })
          //   );
          // }}
          // handleSetPage={(newPage) => {
          //   dispatch(
          //     actions.setPageConfigData({
          //       type: "SET_PAGE",
          //       data: newPage,
          //     })
          //   );
          // }}
          // handleNoOfRowsPerPage={(value) => {
          //   dispatch(
          //     actions.setPageConfigData({
          //       type: "SET_DATA_PER_PAGE",
          //       data: parseInt(value, 10),
          //     })
          //   );
          //   dispatch(actions.setPageConfigData({ type: "SET_PAGE", data: 0 }));
          // }}
          columns={qcDataColumns}
          numCols={qcDataColumns.length}
        />
      </Box>
    </div>
  );
};

export default QCDataSmartCheckTable;
