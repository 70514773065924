import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import "@fortawesome/fontawesome-free/css/all.min.css";
import axios from "axios";
import "core-js";
import "react-app-polyfill/stable";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App";
import "./global.css";
import reportWebVitals from "./reportWebVitals";
import store from "./store";
import { SplashScreenProvider } from "./utils/SplashScreen";

axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    config.headers.Authorization = `Bearer ${token}`;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor
axios.interceptors.response.use(
  (response) => {
    // You can modify the response data here if needed
    return response;
  },
  (error) => {
    // const history = useHistory();

    // console.log(error.response);
    if (error.response && error.response.status === 401) {
      // Log out the user and navigate to the logout page
      // history.push('/auth/logout');
      console.log("error.response");
      // const navigate = useNavigate();
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      window.location.reload();
      // navigate("/");
    }

    // Do something with the response error
    return Promise.reject(error);
  }
);

const cache = createCache({
  key: "css",
  prepend: true,
});

createRoot(document.getElementById("root")).render(
  <CacheProvider value={cache}>
    <Provider store={store}>
      <SplashScreenProvider>
        <App />
      </SplashScreenProvider>
    </Provider>
  </CacheProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
