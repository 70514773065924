import React, { useContext, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import TableCustomServer from "src/helper/Table/TableCustomServer";
import { paymentManageSlice } from "src/store/slice/paymentManageSlice";
import { PaymentManagementContext } from "src/views/PaymentManagement/PaymentManagementRoute";
import {
  PaymentRaisedColumn,
  getFormattedPaymentRaisedData,
} from "./RaisedPaymentTableConfig";
import { qcApprovedColumns } from "src/views/QCManagement/QCManagementApprovedTableConfig";
import { Button } from "react-bootstrap";
import CheckIcon from "@mui/icons-material/Check";
import { Box } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import {
  getPaymentRequestList,
  updatePaymentStatus,
} from "src/apis/paymentManagement";
import { generalSlice } from "src/store/slice/generalSlice";
import { successMessage } from "src/helper/Alert/messages";

const RaisedPaymentTable = ({
  allPaymentRecord = [],
  count = 0,
  page,
  dataCount,
  dataPerPage,
}) => {
  const dispatch = useDispatch();
  const { actions } = paymentManageSlice;
  const context = useContext(PaymentManagementContext);
  const { actions: generalActions } = generalSlice;

  const [rowData, setRowData] = useState(allPaymentRecord);
  const { filter } = useSelector(
    (state) => ({
      filter: state.paymentManage.filter || [],
    }),
    shallowEqual
  );

  useEffect(() => {
    const data = allPaymentRecord?.map((data, i) =>
      getFormattedPaymentRaisedData(data)
    );

    setRowData(data);
  }, [allPaymentRecord]);

  const handleApproved = (row) => {
    updatePaymentStatus({ user_payment_id: row?.id?.idData, status: 2 }).then(
      (res) => {
        // console.log(res);

        dispatch(
          generalActions.pushNewAlert({
            show: true,
            heading: "Success",
            message: successMessage("Payment", "Approved"),
            type: "success",
          })
        );

        getPaymentRequestList({
          search: filter?.data?.search?.keyword || "",
          page_no: 1,
          page_record: 25,
          date: null,
          status: [1, 3],
        })
          .then((resData) => {
            // console.log(resData?.data?.data?.user_role_data);
            dispatch(
              actions.setAllRaisedPayment(resData?.data?.data?.payment_data?.rows)
            );
            dispatch(
              actions.setPageConfigData({
                type: "SET_DATA_COUNT",
                data: resData?.data?.data?.payment_data?.count || 0,
              })
            );
          })
          .catch((data) => console.log(data.response));
        //   .finally(() => {
        //     dispatch(actions.setAllRaisedPayment({}));
        //   });
      }
    );
  };
  const handleRejact = (row) => {
    updatePaymentStatus({ user_payment_id: row?.id?.idData, status: 3 }).then(
      (res) => {
        // console.log(res);

        dispatch(
          generalActions.pushNewAlert({
            show: true,
            heading: "Success",
            message: successMessage("Payment", "Rejected"),
            type: "success",
          })
        );

        getPaymentRequestList({
          search: filter?.data?.search?.keyword || "",
          page_no: 1,
          page_record: 25,
          date: null,
          status: [1, 3],
        })
          .then((res) => {
            // console.log(res?.data?.data?.user_role_data);
            dispatch(
              actions.setAllRaisedPayment(res?.data?.data?.payment_data?.rows)
            );
            dispatch(
              actions.setPageConfigData({
                type: "SET_DATA_COUNT",
                data: res?.data?.data?.payment_data?.count || 0,
              })
            );
          })
          .catch((data) => console.log(data.response));
        //   .finally(() => {
        //     dispatch(actions.roleFetched({}));
        //   });
      }
    );
  };

  const rendnderBtn = (row) => {
    return (
      <Box sx={{ display: "flex", gap: "10px" }}>
        {row?.actions?.hidebtn ? (
          <><Button
            variant={"success"}
            size="sm"
            onClick={() => handleApproved(row)}
          >
            <CheckIcon sx={{ color: "#fff" }} />
          </Button><Button variant={"danger"} size="sm" onClick={() => handleRejact(row)}>
              <ClearIcon sx={{ color: "#fff" }} />
            </Button></>
        ) : "-"}
      </Box>
    );
  };

  return (
    <TableCustomServer
      rowData={rowData}
      editAction={(row) => {
        // qcContext.openViewQCDialog(row.id.user_id);
        // dispatch(actions.userFetched(row));
      }}
      deleteAction={(row) => {
        // qcContext.deleteQC(row.id.user_id);
        // dispatch(actions.userFetched(row));
      }}
      show={false}
      showViewButton={false}
      showEditButton={false}
      showDeleteButton={false}
      showExtraButton={true}
      renderExtraBrn={rendnderBtn}
      page={page}
      dataCount={dataCount}
      dataPerPage={dataPerPage}
      handleRequestSort={(currentKey, order) => {
        const isAsc = order === "asc";
        dispatch(
          actions.setSortConfig({
            key: currentKey,
            order: isAsc ? "desc" : "asc",
          })
        );
      }}
      handleSetPage={(newPage) => {
        dispatch(
          actions.setPageConfigData({
            type: "SET_PAGE",
            data: newPage,
          })
        );
      }}
      handleNoOfRowsPerPage={(value) => {
        dispatch(
          actions.setPageConfigData({
            type: "SET_DATA_PER_PAGE",
            data: parseInt(value, 10),
          })
        );
        dispatch(actions.setPageConfigData({ type: "SET_PAGE", data: 0 }));
      }}
      columnsConfig={PaymentRaisedColumn}
      numCols={PaymentRaisedColumn.length}
      deleteTooltip="Delete User"
      editTooltip="Edit User"
      showMoreButton={false}
    />
  );
};

export default RaisedPaymentTable;
