import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allRoles: [],
  selectedRole: null,
  selectedRolePermisions: null,
  filter: {},
  isLoading: false,
}

export const rolesSlice = createSlice({
  name: "roles",
  initialState: initialState,
  reducers: {
    setAllRoles: (state, action) => {
      state.allRoles = action.payload;
    },
    roleFetched: (state, action) => {
      state.selectedRole = action.payload;
    },
    setSelectedRolePermisions: (state, action) => {
      state.selectedRolePermisions = action.payload;
    },
    removeSelectedRole: (state) => {
      state.selectedRole = null;
    },
    setFilter: (state, action) => {
      state.filter = action.payload.data;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});
