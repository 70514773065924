import { CNavItem } from "@coreui/react";
import { VerifiedUserOutlined } from "@mui/icons-material";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import DevicesOtherOutlinedIcon from "@mui/icons-material/DevicesOtherOutlined";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import ListAltIcon from "@mui/icons-material/ListAlt";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import SourceIcon from "@mui/icons-material/Source";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import BookIcon from "@mui/icons-material/Book";
import ExtensionIcon from "@mui/icons-material/Extension";
import GroupsIcon from "@mui/icons-material/Groups";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import RecentActorsIcon from "@mui/icons-material/RecentActors";
import ReceiptIcon from "@mui/icons-material/Receipt";
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import PhoneForwardedIcon from '@mui/icons-material/PhoneForwarded';

const _nav = [
  {
    id: 1,
    component: CNavItem,
    name: "Dashboard",
    to: "/dashboard",
    icon: <DashboardOutlinedIcon />,
    // badge: {
    //   color: 'info',
    //   text: 'NEW',
    // },
  },
  {
    id: 2,
    component: CNavItem,
    name: "Project Management",
    to: "/project-management",
    icon: <DevicesOtherOutlinedIcon />,
  },
  {
    id: 10,
    component: CNavItem,
    name: "User Management",
    to: "/user-management",
    icon: <AccountTreeOutlinedIcon />,
  },
  {
    id: 15,
    component: CNavItem,
    name: "Permissions",
    to: "/permissions",
    icon: <AccountTreeOutlinedIcon />,
  },
  {
    id: 16,
    component: CNavItem,
    name: "Role Management",
    to: "/role-management",
    icon: <ManageAccountsOutlinedIcon />,
  },
  {
    id: 20,
    component: CNavItem,
    name: "Query Management",
    to: "/query-management",
    icon: <QueryStatsIcon />,
  },
  {
    id: 21,
    component: CNavItem,
    name: "Data Bank",
    to: "/data-bank",
    icon: <SourceIcon />,
  },
  {
    id: 22,
    component: CNavItem,
    name: "Payment Management",
    to: "/payment-management",
    icon: <PaymentsOutlinedIcon />,
  },
  {
    id: 23,
    component: CNavItem,
    name: "Audit Logs",
    to: "/audit-logs",
    icon: <ListAltIcon />,
  },
  {
    id: 24,
    component: CNavItem,
    name: "QC Management",
    to: "/qc-management",
    icon: <GroupsIcon />,
  },
  {
    id: 25,
    component: CNavItem,
    name: "Kyc Management",
    to: "/kyc-management",
    icon: <PendingActionsIcon />,
  },
  {
    id: 28,
    component: CNavItem,
    name: "Freelancer Management",
    to: "/freelancer-management",
    icon: <VerifiedUserOutlined />,
  },
  {
    id: 32,
    component: CNavItem,
    name: "Slot Management",
    to: "/slot-management",
    icon: <BookIcon />,
  },
  {
    id: 36,
    component: CNavItem,
    name: "Extensions Management",
    to: "/extensions-management",
    icon: <ExtensionIcon />,
  },
  {
    id: 132,
    component: CNavItem,
    name: "Contacts",
    to: "/contacts",
    icon: <RecentActorsIcon />,
  },
  {
    id: 133,
    component: CNavItem,
    name: "Transactions",
    to: "/transactions",
    icon: <ReceiptIcon />,
  },
  {
    id: 134,
    component: CNavItem,
    name: "Freelance Transactions",
    to: "/freelance-transactions",
    icon: <ReceiptLongIcon />,
  },
  {
    id: 135,
    component: CNavItem,
    name: "Call Requests",
    to: "/call-requests",
    icon: <PhoneForwardedIcon />,
  }
];

export default _nav;
