import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allDataBank: [],
  selectedDataBank: null,
  selectedDataBankProfile: null,
  filter: {},
  selectedProjectData: null,
  isLoading: false,
  page: 0,
  dataCount: 0,
  dataPerPage: 10,
  sort: {
    updatedAt: "desc",
  },
};

export const dataBankSlice = createSlice({
  name: "dataBankManagement",
  initialState: initialState,
  reducers: {
    setAllDataBankData: (state, action) => {
      state.allDataBank = action.payload;
    },
    setSelectedData: (state, action) => {
      state.selectedDataBank = action.payload;
    },
    setSelectedDataBankProfile: (state, action) => {
      state.selectedDataBankProfile = action.payload;
    },
    queryFetchedData: (state, action) => {
      state.selectedDataBank = action.payload;
    },
    setFilterData: (state, action) => {
      state.filter = action.payload.data;
    },
    setSelectedProjectData: (state, action) => {
      state.selectedProjectData = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setPageConfigData: (state, action) => {
      switch (action.payload.type) {
        case "SET_PAGE":
          state.page = action.payload.data;
          break;
        case "SET_DATA_COUNT":
          state.dataCount = action.payload.data;
          break;
        case "SET_IS_LOADING":
          state.isLoading = action.payload.data;
          break;
        case "SET_DATA_PER_PAGE":
          state.dataPerPage = action.payload.data;
          break;
        default:
          break;
      }
    },
  },
});
