import { getCurrentDateTime } from "src/utils/utils";

export const getFormattedQCData = (data) => ({
  id: {
    display: true,
    label: "id",
    data: "#" + data?.qc_management_id || "-",
  },
  project_name: {
    display: true,
    label: "project_name",
    data: data?.project_name || "-",
  },
  user_name: {
    display: true,
    label: "user_name",
    data: data?.user_name || "-",
    user_id: data?.user_id,
  },
  slot_id: {
    display: true,
    label: "slot_id",
    data: "#" + data?.user_slot_id || "-",
  },
  total_data: {
    display: true,
    label: "total_data",
    data: data?.total_data || "-",
  },
  total_submit_data: {
    display: true,
    label: "total_submit_data",
    data: data?.total_submit_data || "-",
  },
  inaccurate_data: {
    display: true,
    label: "inaccurate_data",
    data: data?.inaccurate_data?.toString() || "-",
  },
  accuracy: {
    display: true,
    label: "accuracy",
    data: data?.accurate_data?.toString() || "-",
  },
  slot_submissions: {
    display: true,
    label: "slot_submissions",
    data: data?.slot_submission_date
      ? getCurrentDateTime(new Date(data?.slot_submission_date))
      : "-",
    date: data?.slot_submission_date,
  },
  created_date: {
    display: true,
    label: "created_date",
    data: data?.created_datetime
      ? getCurrentDateTime(new Date(data?.created_datetime))
      : "-",
    date: data?.created_datetime,
  },
  all_data: {
    display: false,
    label: "all_data",
    data: data,
  },
});

export const qcColumns = [
  {
    id: "id",
    label: "QC ID",
    styles: { maxWidth: "100px", width: "50px" },
    align: "center",
    sort: true
  },
  {
    id: "project_name",
    label: "Project Name",
    align: "left",
    sort: true
  },

  {
    id: "user_name",
    label: "Username",
    // styles: { maxWidth: "120px", width: "120px" },
    align: "left",
    sort: true,
  },
  {
    id: "slot_id",
    label: "Slot ID",
    // styles: { maxWidth: "120px", width: "120px" },
    align: "left",
    sort: true,
  },
  {
    id: "total_data",
    label: "Total Data",
    align: "left",
    sort: false,
  },
  {
    id: "total_submit_data",
    label: "Total Submit Data",
    align: "left",
    sort: false,
  },
  {
    id: "inaccurate_data",
    label: "Inaccurate Data",
    align: "left",
    sort: false,
  },
  {
    id: "accuracy",
    label: "Accuracy",
    align: "left",
    sort: false,
  },
  {
    id: "slot_submissions",
    label: "Slot Submissions",
    align: "left",
    sort: true,
  },
  {
    id: "created_date",
    label: "Created Date",
    align: "left",
    sort: true,
  },
  {
    label: "Actions",
    align: "center",
    styles: { maxWidth: "160px", width: "160px" },
  },
];
