import { Chip } from "@mui/material";
import { getCurrentDateTime } from "src/utils/utils";
import { MyButton } from "../../RaisedPayment/components/RaisedPaymentTableConfig";

export const PaymentApprovedColumn = [
  {
    id: "payment_ticket_id",
    label: "Payment Ticket ID",
    align: "left",
    sort: true,
  },
  {
    id: "user_name",
    label: "User Name",
    align: "left",
    sort: true,
  },
  {
    id: "raised_ammount",
    label: "Raised Ammount",
    align: "left",
    sort: true,
  },
  {
    id: "ticket_date",
    label: "Ticket Date",
    align: "left",
    sort: true,
  },
  {
    id: "ticket_status",
    label: "Ticket Status",
    align: "left",
    sort: true,
  },
  {
    id:"actions",
    label: "Actions",
    align: "center",
    styles: { maxWidth: "160px", width: "160px" },
  },
];

export const getFormattedPaymentApprovedData = (data) => ({
  id: {
    display: true,
    label: "id",
    data: <MyButton id={data?.user_payment_id} data={data} /> || "-",
    idData: data?.user_payment_id || "-",
  },
  user_name: {
    display: true,
    label: "user_name",
    data: data?.user?.user_name || "-",
  },
  raised_ammount: {
    display: true,
    label: "raised_ammount",
    data: data?.amount?.toString() || "-",
  },
  ticket_date: {
    display: true,
    label: "ticket_date",
    data: data?.created_datetime
      ? getCurrentDateTime(new Date(data?.created_datetime))
      : "-",
    dob: data?.created_datetime,
  },
  ticket_status: {
    display: true,
    label: "status",
    data: data?.status ? (
      <Chip
        label={"Approved"}
        sx={{
          // color: "#fff",
          fontWeight: "600",
          backgroundColor: "#d1c4e9",
          color: "#512da8",
          border: "1px solid",
          borderColor: "#512da8",
        }}
      />
    ) : (
      "-"
    ),
    status: data?.status,
    actions:{
      hidebtn:true
    }
  },
});
