import React, { createContext } from "react";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { paymentManageSlice } from "src/store/slice/paymentManageSlice";
import { useTheme } from "@emotion/react";
import { Box, Dialog, DialogActions, DialogContent } from "@mui/material";
import { Formik } from "formik";
import {
  Button,
  Col,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  Row,
} from "react-bootstrap";
import DialogCloseTitle from "src/helper/Dialog/DialogTitle";
import { closeModal } from "src/helper/Dialog/dialogUtils";
import BootstrapButton from "src/helper/UI/Button/BootstrapButton";
import { generalSlice } from "src/store/slice/generalSlice";
import { updatePaymentStatus } from "src/apis/paymentManagement";

const ViewPaymentDetailDialog = ({ onHide, show }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { selectedPaymentListData } = useSelector(
    (state) => ({
      selectedPaymentListData:
        state.paymentManage?.selectedPaymentListData || null,
    }),
    shallowEqual
  );

  const { actions } = paymentManageSlice;
  const dispatch = useDispatch();
  const theme = useTheme();
  const { actions: generalActions } = generalSlice;

  const init = {
    id: selectedPaymentListData?.user_payment_id || "",
    desc: selectedPaymentListData?.approved_data || "",
  };

  const submitTheData = (data) => {
    // updatePaymentStatus({ user_payment_id: data.id, status: 2 }).then(
    //   (res) => {
    //     // console.log(res);

    //     dispatch(
    //       generalActions.pushNewAlert({
    //         show: true,
    //         heading: "Success",
    //         message: successMessage("Payment", "Approved"),
    //         type: "success",
    //       })
    //     );

    //     getPaymentRequestList({
    //       search: filter?.data?.search?.keyword || "",
    //       page_no: 1,
    //       page_record: 25,
    //       date: null,
    //       status: [1, 3],
    //     })
    //       .then((res) => {
    //         // console.log(res?.data?.data?.user_role_data);
    //         dispatch(
    //           actions.setAllRaisedPayment(res?.data?.data?.payment_data?.rows)
    //         );
    //         dispatch(
    //           actions.setPageConfigData({
    //             type: "SET_DATA_COUNT",
    //             data: res?.data?.data?.payment_data?.count || 0,
    //           })
    //         );
    //       })
    //       .catch((data) => console.log(data.response));
    //     //   .finally(() => {
    //     //     dispatch(actions.setAllRaisedPayment({}));
    //     //   });
    //   }
    // );
  };

  return (
    <Formik
      initialValues={init}
      enableReinitialize
      onSubmit={(values, { resetForm, setSubmitting }) => {
        submitTheData(values)
          .then(() => {
            onHide();
            resetForm();
            // closeModal({ , resetForm });
          })
          .finally(() => {
            setSubmitting(false);
          });
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        setFieldTouched,
        resetForm,
        setFieldValue,
        isValid,
        errors,
        touched,
        isSubmitting,
      }) => (
        <Dialog open={show} scroll={"paper"} maxWidth="md" fullWidth={true}>
          <Form onSubmit={handleSubmit} noValidate>
            <DialogCloseTitle
              onClose={() => {
                // closeModal({ , resetForm });
                onHide();
                resetForm();
              }}
              // isCloseButtonDisabled={isSubmitting}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    fontSize: "1.5rem",
                    fontWeight: 600,
                    color: theme.palette.primary.main,
                  }}
                >
                  Ammount Paid Payment Detail
                </div>
              </div>
            </DialogCloseTitle>
            <DialogContent dividers={true}>
              <Row>
                <Col style={{ marginTop: "10px" }} sm={6}>
                  <FormGroup>
                    <FormLabel style={{ fontWeight: 600 }}>
                      Payment Tickit Number
                    </FormLabel>
                    <FormControl
                      type="text"
                      name="id"
                      value={values.id}
                      readOnly
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row style={{ marginTop: "10px" }}>
                <Col sm={12}>
                  <FormGroup className="required">
                    <FormLabel style={{ fontWeight: 600 }}>
                      Payment Given Details
                    </FormLabel>
                    <FormControl
                      style={{ resize: "none" }}
                      type="textarea"
                      name={"desc"}
                      as={"textarea"}
                      value={values.desc}
                      // multiple
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {/* <FormControl.Feedback type="invalid">
                            {errors.name}
                          </FormControl.Feedback> */}
                  </FormGroup>
                </Col>
              </Row>
            </DialogContent>
            <DialogActions>
              <Button
                variant="secondary"
                onClick={closeModal({ onHide, resetForm })}
                disabled={isSubmitting}
              >
                Cancel
              </Button>

              <BootstrapButton
                variant="success"
                type="submit"
                disabled
                label="Submit"
                labelWhenSubmitting="Submitting"
                isSubmitting={isSubmitting}
                onClick={handleSubmit}
                // disabled={!isValid}
              />
            </DialogActions>
          </Form>
        </Dialog>
      )}
    </Formik>
  );
};

export default ViewPaymentDetailDialog;
