import { Box, Divider, Paper } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import EnhancedTableToolbar from "src/helper/EnhancedTableToolbar/EnhancedTableToolbar";
import { qcManagementSlice } from "src/store/slice/qcManagement";
import { QCManagementContext } from "src/views/QCManagement/QCManagementRoute";
import QCDataList from "./components/QCDataList";
import QCDataTable from "./components/QCDataTable";
import { Col, Row } from "react-bootstrap";
import { getCurrentDateTime } from "src/utils/utils";
import { getQCData, getQCProjectDataList } from "src/apis/qcManagement";

const getFormattedQCData = (data) => ({
  freelancer: data?.user?.user_name,
  email: data?.user?.email_address,
  mobileNo: data?.user?.phone_number,
  username: data?.user?.user_id,
  count: data?.user_slot?.max_data_assign,
  submissionOn: data?.user_slot?.created_datetime
    ? getCurrentDateTime(new Date(data?.user_slot?.created_datetime))
    : "",
  registeredOn: data?.user?.created_datetime
    ? getCurrentDateTime(new Date(data?.user?.created_datetime))
    : "",
  accurateData: data?.accurate_data,
  result: data?.result,
  payout: data?.payout,
  totalPayout: data?.total_payout,
});

const QCDataView = () => {
  const { actions } = qcManagementSlice;
  const dispatch = useDispatch();
  const context = useContext(QCManagementContext);
  const [formattedData, setFormattedData] = React.useState({});

  // const [status, setStatus] = useState("");

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { allQC, selectedQC, qcDataView, selectedQCListData } = useSelector(
    (state) => ({
      allQC: state.qcManagement.allQC || [],
      selectedQC: state.qcManagement.selectedQC || {},
      selectedQCListData: state.qcManagement.selectedQCListData || {},
      qcDataView: state.qcManagement.qcDataView || {},
    }),
    shallowEqual
  );

  useEffect(() => {
    const data = getFormattedQCData({
      ...selectedQCListData,
      ...selectedQC?.data,
    });
    setFormattedData(data);
  }, [selectedQC, selectedQCListData]);

  // console.log("selectedQC", selectedQC);

  const getAllData = (id) => {
    getQCData(id)
      .then((res) => {
        dispatch(actions.selectedQCListDataFetched(res?.data?.data?.qc_data));
        getQCProjectDataList({
          qc_management_id: id,
          page_no: 1,
          page_record: 25,
        })
          .then((res) => {
            // console.log(res);
            dispatch(
              actions.setQCDataView({
                type: "SET_DATA",
                data: res?.data?.data?.qc_management_project_data?.rows,
              })
            );
            dispatch(
              actions.setQCDataView({
                type: "SET_DATA_COUNT",
                data: res?.data?.data?.qc_management_project_data?.count,
              })
            );
          })
          .catch((data) => console.log(data.response));
      })
      .catch((data) => console.log(data.response));
  };

  useEffect(() => {
    selectedQC?.data?.qc_management_id &&
      getAllData(selectedQC?.data?.qc_management_id);
  }, [selectedQC]);

  return (
    <>
      <Paper sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
        <EnhancedTableToolbar
          title="View QC Data"
          // btnHandler={() => context.addRole()}
          //   refreshHandler={getAllData}
          // circularLoader={
          //   isLoading && (
          //     <span style={{ marginLeft: "5px" }}>
          //       <Spinner animation="border" />
          //     </span>
          //   )
          // }
          showAdd={false}
          shouldGetDataOnSearchInput={false}
        />
      </Paper>
      <Paper
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          marginTop: "10px",
        }}
      >
        <Box
          sx={{
            width: "100%",
            justifyContent: "space-between",
            display: "flex",
            alignItems: "center",
            padding: "10px 20px",
            fontWeight: "bold",
            fontSize: "20px",
          }}
        >
          <Box>QC ID:- {selectedQCListData?.qc_management_id || ""}</Box>
          <Box>Project Name: {selectedQCListData?.project_name || ""}</Box>
        </Box>
      </Paper>

      <Row style={{ marginTop: "10px" }}>
        <Col md={3}>
          <Paper
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              padding: "16px",
            }}
          >
            <QCDataList data={formattedData} />
          </Paper>
        </Col>
        <Col md={9} style={{ paddingLeft: "0" }}>
          <Paper
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              padding: "16px",
            }}
          >
            <QCDataTable
              page={qcDataView?.page}
              dataCount={qcDataView?.dataCount}
              dataPerPage={qcDataView?.dataPerPage}
              data={qcDataView?.data}
              parentId={selectedQCListData?.qc_management_id}
            />
          </Paper>
        </Col>
      </Row>
    </>
  );
};

export default QCDataView;
