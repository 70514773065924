import { Box, Chip } from "@mui/material";
import { getCurrentDateTime } from "src/utils/utils";
import { PaymentManagementContext } from "src/views/PaymentManagement/PaymentManagementRoute";
import React, { useContext } from "react";
import { paymentManageSlice } from "src/store/slice/paymentManageSlice";
import { useDispatch } from "react-redux";

export const MyButton = ({ id, data }) => {
  const context = useContext(PaymentManagementContext);
  const { actions } = paymentManageSlice;
  const dispatch = useDispatch();
  return (
    <Box
      onClick={() => {
        context.openViewPaymentDialog(id);
        dispatch(actions.selectedPaymentListDataFetched(data));
      }}
      sx={{
        cursor: "pointer",
        color: "#1976d2",
        textDecoration: "underline",
      }}
    >
      {id}
    </Box>
  );
};

export const PaymentRaisedColumn = [
  {
    id: "payment_ticket_id",
    label: "Payment Ticket ID",
    align: "left",
    sort: true,
  },
  {
    id: "user_name",
    label: "User Name",
    align: "left",
    sort: true,
  },
  {
    id: "raised_ammount",
    label: "Raised Ammount",
    align: "left",
    sort: true,
  },
  {
    id: "ticket_date",
    label: "Ticket Date",
    align: "left",
    sort: true,
  },
  {
    id: "ticket_status",
    label: "Ticket Status",
    align: "left",
    sort: true,
  },
  {
    id: "action",
    label: "Actions",
    align: "center",
    styles: { maxWidth: "160px", width: "160px" },
  },
];

export const getFormattedPaymentRaisedData = (data) => ({
  id: {
    display: true,
    label: "id",
    data: data?.user_payment_id || "-",
    idData: data?.user_payment_id || "-",
  },
  user_name: {
    display: true,
    label: "user_name",
    data: data?.user?.user_name || "-",
  },
  raised_ammount: {
    display: true,
    label: "raised_ammount",
    data: data?.amount?.toString() || "-",
  },
  ticket_date: {
    display: true,
    label: "ticket_date",
    data: data?.created_datetime
      ? getCurrentDateTime(new Date(data?.created_datetime))
      : "-",
    dob: data?.created_datetime,
  },
  ticket_status: {
    display: true,
    label: "status",
    data: data?.status ? (
      <Chip
        label={data?.status === 1 ? "Open" : data?.status === 3 ? "Reject" : '- '}
        sx={{
          // color: "#fff",
          fontWeight: "600",
          backgroundColor: data?.status === 1 ? "#e3f2fd" : "#ffe0b2",
          color: data?.status === 1 ? "#0d47a1" : "#ff9800",
          border: "1px solid",
          borderColor: data?.status === 1 ? "#0d47a1" : "#ff9800",
        }}
      />
    ) : (
      "-"
    ),
    status: data?.status,
  },
  actions:{
    hidebtn:data?.status === 1 ? true : false
  }
});
