import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { Col } from "react-bootstrap";
import { QCManagementContext } from "../../QCManagementRoute";
import { useContext } from "react";
import { qcManagementSlice } from "src/store/slice/qcManagement";
import { useDispatch } from "react-redux";

const QCFilter = ({
  searchConfig: { searchKeys = [], filterValue = "", setSearchConfig } = {},
}) => {

  const changeFilterHandler = (value) => {
    const dataFilter = {
      search: {
        keys: searchKeys,
        keyword: value,
      },
    };
    setSearchConfig(dataFilter);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-end",
        gap: "12px",
        padding: "12px",
      }}
    >
      <Col sm={6} lg={4}>
        <TextField
          fullWidth={true}
          sx={{
            "& legend": {
              width: "0px",
            },
          }}
          // className={classes.input}
          // inputProps={{ style: { padding: "11px 4px" } }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: filterValue && (
              <InputAdornment position="end">
                <IconButton
                  size="small"
                  onClick={() => changeFilterHandler("")}
                >
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            ),
            autoComplete: "off",
          }}
          inputProps={{
            style: {
              padding: "10px 14px",
            },
          }}
          value={filterValue}
          variant="outlined"
          // sx={{
          //   border:"1px #aeaeae solid",
          //   borderRadius:"4px"
          // }}
          onChange={(e) => {
            const { value } = e.target;
            changeFilterHandler(value);
          }}
          name="searchText"
          placeholder="Search"
        />
      </Col>
    </div>
  );
};

export default QCFilter;
