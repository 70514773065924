import React, { useEffect, useState } from "react";
import { getUserByToken } from "src/apis/login";
import { useNavigate } from "react-router-dom";
import { AppHeader, AppSidebar } from "./components";

const Layout = ({ children }) => {
  // const [isAuthorised, setIsAuthorised] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (getUserByToken()) {
      // setIsAuthorised(true);
    } else {
      // setIsAuthorised(false);
      navigate("/auth/login");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div style={{ height: "100%" }}>
      <AppSidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <AppHeader />
        <div className="body flex-grow-1 px-3 mb-3">{children}</div>
        {/* <AppFooter /> */}
      </div>
    </div>
  );
};

export default Layout;
