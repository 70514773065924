import PaymentManagementRoute from "./views/PaymentManagement/PaymentManagementRoute";

const { lazy } = require("react");

const QCManagementRoute = lazy(() =>
  import(
    "./views/QCManagement/QCManagementRoute" /* webpackChunkName: "QCManagementRoute" */
  )
);
const PermissionsPage = lazy(() =>
  import(
    "./views/Permission/PermissionRoute" /* webpackChunkName: "permissions" */
  )
);
const RolesPage = lazy(() =>
  import("./views/Roles/RolesRoute.js" /* webpackChunkName: "RolesRoute" */)
);
const UserManagementPage = lazy(() =>
  import(
    "./views/UserManagement/UserManagementRoute.js" /* webpackChunkName: "UserManagement" */
  )
);
const ProjectManagementPage = lazy(() =>
  import(
    "./views/ProjectManagement/ProjectManagementRouts.js" /* webpackChunkName: "UserManagement" */
  )
);

const QueryPage = lazy(() =>
  import("./views/query/QueryRoute" /* webpackChunkName: "permissions" */)
);

const QueryViewRoute = lazy(() =>
  import("./views/query/QueryViewRoute" /* webpackChunkName: "permissions" */)
);

const DataBankRoute = lazy(() =>
  import("./views/dataBank/DataBankRoute" /* webpackChunkName: "permissions" */)
);

const DataBankViewRoute = lazy(() =>
  import(
    "./views/dataBank/DataBankViewRoute" /* webpackChunkName: "permissions" */
  )
);

const kycManagementPage = lazy(() =>
  import(
    "./views/kycManagementFile/kycManagementRoutes" /* webpackChunkName: "kyc" */
  )
);

const AuditLogsPage = lazy(() =>
  import("./views/Logs/AuditLogsRoute" /* webpackChunkName: "permissions" */)
);

const FreelancersPage = lazy(() =>
  import(
    "./views/Freelancer/FreelancerRoute" /* webpackChunkName: "permissions" */
  )
);

const FreelancersBulkUploadPage = lazy(() =>
  import(
    "./views/Freelancer/FreelancerBulkUploadRoute" /* webpackChunkName: "permissions" */
  )
);

const ExtensionManagementPage = lazy(() =>
  import(
    "./views/ExtensionManagement/ExtensionManagementRoute" /* webpackChunkName: "permissions" */
  )
);

const SlotManagementPage = lazy(() =>
  import(
    "./views/SlotManagement/SlotManagementRoute" /* webpackChunkName: "permissions" */
  )
);

const contactsPage = lazy(() =>
  import("./views/contact/ContactRoutes" /* webpackChunkName: "contacts" */)
);

const TransactionPage = lazy(() =>
  import(
    "./views/Transaction/TransactionRoute" /* webpackChunkName: "Transaction" */
  )
);

const FreelanceTransactionPage = lazy(() =>
  import(
    "./views/FreelanceTransaction/FreelanceTransactionRoute" /* webpackChunkName: "freeelanceTransaction" */
  )
);

const CallRequestPage = lazy(() =>
  import(
    "./views/CallRequest/CallRerquestRoutes" /* webpackChunkName: "CallRequest" */
  )
);

export const customRoutes = (permissions, licenseFeatures) => [
  {
    path: "/permissions/*",
    Component: PermissionsPage,
    isActive: true,
  },
  {
    path: "/contacts/*",
    Component: contactsPage,
    isActive: true,
  },
  {
    path: "/call-requests/*",
    Component: CallRequestPage,
    isActive: true,
  },
  {
    path: "/transactions/*",
    Component: TransactionPage,
    isActive: true,
  },
  {
    path: "/freelance-transactions/*",
    Component: FreelanceTransactionPage,
    isActive: true,
  },
  {
    path: "/role-management/*",
    Component: RolesPage,
    isActive: true,
  },
  {
    path: "/user-management/*",
    Component: UserManagementPage,
    isActive: true,
  },
  {
    path: "/project-management/*",
    Component: ProjectManagementPage,
    isActive: true,
  },
  {
    path: "/query-management/*",
    Component: QueryPage,
    isActive: true,
  },
  {
    path: "/query-management/view",
    Component: QueryViewRoute,
    isActive: true,
  },
  {
    path: "/data-bank/*",
    Component: DataBankRoute,
    isActive: true,
  },
  {
    path: "/data-bank/:id/view",
    Component: DataBankViewRoute,
    isActive: true,
  },
  {
    path: "/audit-logs/*",
    Component: AuditLogsPage,
    isActive: true,
  },
  {
    path: "/freelancer-management/*",
    Component: FreelancersPage,
    isActive: true,
  },
  {
    path: "/freelancer-management/bulk-upload",
    Component: FreelancersBulkUploadPage,
    isActive: true,
  },
  {
    path: "/extensions-management/*",
    Component: ExtensionManagementPage,
    isActive: true,
  },
  {
    path: "/slot-management/*",
    Component: SlotManagementPage,
    isActive: true,
  },
  {
    path: "/qc-management/*",
    Component: QCManagementRoute,
    isActive: true,
  },
  {
    path: "/payment-management/*",
    Component: PaymentManagementRoute,
    isActive: true,
  },
];

export const customRoutesKycManagement = (permissions, licenseFeatures) => [
  {
    path: "/kyc-management/*",
    Component: kycManagementPage,
    isActive: true,
  },
];
