import React, { useEffect } from 'react'
import PayedTickitTable from './components/PayedTickitTable'
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { paymentManageSlice } from 'src/store/slice/paymentManageSlice';

const PayedTickit = () => {
    const { allPaymentRecord, page, dataCount, dataPerPage } = useSelector(
        (state) => ({
          allPaymentRecord: state.paymentManage?.payed?.allPaymentRecord || [],
          page: state.paymentManage?.payed?.page || 0,
          dataCount: state.paymentManage?.payed?.dataCount || 0,
          dataPerPage: state.paymentManage?.payed?.dataPerPage || 25,
        }),
        shallowEqual
      );
      const { actions } = paymentManageSlice;
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(actions.setAllPayedPayment([]));
    };
  },[]);
  return (
    <PayedTickitTable
    allPaymentRecord={allPaymentRecord}
    page={page}
    dataCount={dataCount}
    dataPerPage={dataPerPage} />
  )
}

export default PayedTickit