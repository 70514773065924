import { Box } from "@mui/material";
import React from "react";

const QCDataList = ({ data }) => {
  // console.log("data", data);
  return (
    <Box>
      <Box>
        <Box
          sx={{
            fontWeight: "bold",
            fontSize: "18px",
            textDecoration: "underline",
          }}
        >
          Slot Selection Details
        </Box>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "auto 1fr",
            gap: "10px",
            padding: "10px 0px",
          }}
        >
          <span>Freelancer:</span>
          <span>{data?.freelancer || "---NO DATA---"}</span>
          <span>Email:</span>
          <span>{data?.email || "---NO DATA---"}</span>
          <span>mobile No:</span>
          <span>{data?.mobileNo || "---NO DATA---"}</span>
          <span>Username:</span>
          <span>{data?.username || "---NO DATA---"}</span>
          <span>Register on:</span>
          <span>{data?.registeredOn || "---NO DATA---"}</span>
        </Box>
      </Box>
      <Box>
        <Box
          sx={{
            fontWeight: "bold",
            fontSize: "18px",
            textDecoration: "underline",
          }}
        >
          Slot Submission Details
        </Box>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "auto 1fr",
            gap: "10px",
            padding: "10px 0px",
          }}
        >
          <span>Total Data:</span>
          <span>{data?.count || "---NO DATA---"}</span>
          <span>Submission On:</span>
          <span>{data?.submissionOn || "---NO DATA---"}</span>
        </Box>
      </Box>
      <Box>
        <Box
          sx={{
            fontWeight: "bold",
            fontSize: "18px",
            textDecoration: "underline",
          }}
        >
          QC Payment Details
        </Box>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "auto 1fr",
            gap: "10px",
            padding: "10px 0px",
          }}
        >
          <span>Total Data:</span>
          <span>{data?.count || "---NO DATA---"}</span>
          <span>Accurate Data:</span>
          <span>{data?.accurateData?.toString() || "---NO DATA---"}</span>
          <span>Result:</span>
          <span>{data?.result || "---NO DATA---"}</span>
          <span>Payout Rate:</span>
          <span>{data?.payout || "---NO DATA---"}</span>
          <span>Total Payout:</span>
          <span>{data?.totalPayout || "---NO DATA---"}</span>
        </Box>
      </Box>
    </Box>
  );
};

export default QCDataList;
