import { createSlice } from "@reduxjs/toolkit";

export const HANDLERS = {
  INITIALIZE: 'INITIALIZE',
  SIGN_IN: 'SIGN_IN',
  SIGN_OUT: 'SIGN_OUT'
};

const INIT_STATE = {
  token:"",
  user:{}
};

export const usersSlice = createSlice({
  name: "users",
  initialState: INIT_STATE,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setUsersData: (state, action) => {
      state.token = action.payload.data.token;
      state.user = action.payload.data.user;
    },
    requestUser: (state, action) => {
      state.token = action.payload.data.token;
      state.user = action.payload.data.user;
    },
  },
});

export const { setLoading, setUsersData } = usersSlice.actions;

export const usersState = (state) => state.users;

export default usersSlice.reducer;
