import { Suspense, useEffect, useRef } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  Navigate,
  Route,
  Routes as Switch,
  useNavigate,
} from "react-router-dom";
import { customRoutes, customRoutesKycManagement } from "./BasePageConfig";
import ConfirmationModal from "./helper/Dialog/ConfirmationModal";
import { LayoutSplashScreen } from "./utils/SplashScreen";

export function ErrorPage() {
  return (
    <div className="d-flex flex-column flex-root" style={{ height: "100%" }}>
      <div
        className="d-flex flex-row-fluid flex-column bgi-size-cover bgi-position-center bgi-no-repeat p-10 p-sm-30"
      //   style={{
      //     backgroundImage: `url(${toAbsoluteUrl("/media/error/bg1.jpg")})`,
      //   }}
      >
        <h1
          className="font-size-sm-100 font-weight-boldest text-dark-75 mt-15"
          style={{ fontSize: "150px" }}>
          404
        </h1>
        <p className="font-size-h3 font-weight-light">
          {/* OOPS! Something went wrong here */}
          OOPS! Page not found!!
        </p>
      </div>
    </div>
  );
}

export default function ({
  permissions,
  licenseFeatures,
  isRequestedLicense,
  landingPage,
}) {
  const { idleTimeoutInMin, idleTimeInMin } = useSelector(
    ({ auth }) => ({
      idleTimeoutInMin: auth.idleTimeoutInMin,
      idleTimeInMin: auth.idleTimeInMin,
    }),
    shallowEqual
  );

  const idleTimer = useRef(null);

  //   const { actions: medialibActions } = medialibrarySlice;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const [idleTime, setIdleTime] = useState();
  let timer = null;
  const clearTimeOut = () => {
    if (timer) {
      //console.log(timer);
      // console.log("stop timer");
      clearTimeout(timer);
      timer = null;
    }
  };

  const handleOnIdle = (event) => {
    // console.log("user is idle");
    // console.log("start timer");
    timer = setTimeout(() => {
      navigate("/logout");
    }, idleTimeoutInMin * 60 * 1000);
    // console.log("last active", getLastActiveTime());
  };

  const handleOnActive = (event) => {
    // console.log("user is active", event);
    // console.log("time remaining", getRemainingTime());
    clearTimeOut();
  };

  const handleOnAction = (event) => {
    clearTimeOut();
    // console.log("user did something", event);
  };

  //   useEffect(() => {
  //     getSetting("AUTH_SETTINGS").then(({ data }) => {
  //       console.log(data);
  //       const iTime = data?.authSettings?.idleScreenConfig?.idleTimeInMin;
  //       const iOutTime = data?.authSettings?.idleScreenConfig?.idleTimeoutInMin;
  //       const iTimeEnabled =
  //         data?.authSettings?.idleScreenConfig?.isEnable || false;
  //       if (iTimeEnabled) {
  //         dispatch(actions.setIdleTimeoutInMin(iTime, iOutTime));
  //         // setIdleTime(iTime * 60 * 1000); //minutes -> miliseconds
  //       }
  //     });
  //   }, []);

  useEffect(() => {
    if (idleTimeInMin && idleTimer.current) {
      idleTimer.current.start();
    }

    return () => {
      if (idleTimeInMin && idleTimer.current) {
        clearTimeOut();
        idleTimer.current.stop();
      }
    };
  }, [idleTimeInMin]);
  // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      {/* {idleTimeInMin && (
        <IdleTimer
          ref={(ref) => {
            idleTimer.current = ref;
          }}
          timeout={idleTimeInMin * 60 * 1000}
          onIdle={handleOnIdle}
          onActive={handleOnActive}
          onAction={handleOnAction}
          debounce={500}
          startManually={true}
        />
      )} */}

      <Switch>
        <Route exact path="/" element={<Navigate to="/dashboard" replace />} />
        {customRoutes(permissions, licenseFeatures).map(
          ({ path, Component, isActive }) =>
            isActive && <Route key={path} path={path} element={<Component />} />
        )}
        {customRoutesKycManagement(permissions, licenseFeatures).map(
          ({ path, Component, isActive }) =>
            isActive && <Route key={path} path={path} element={<Component />} />
        )}
        <Route path="*" element={ErrorPage} />
      </Switch>

      {/* <FileUpload actions={medialibActions} /> */}
      <ConfirmationModal />
    </Suspense>
  );
}
