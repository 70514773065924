import { useTheme } from "@emotion/react";
import { Box } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import TableCustomServer from "src/helper/Table/TableCustomServer";
import { qcManagementSlice } from "src/store/slice/qcManagement";
import { QCManagementContext } from "src/views/QCManagement/QCManagementRoute";

const getFormattedQCData = (data) => ({
  id: {
    display: true,
    label: "id",
    data: data?.project_data_id || "-",
  },
  field_name: {
    display: true,
    label: "field_name",
    data: data?.qc_data?.[0]?.feild_title || "-",
  },
  erroe_content_data: {
    display: true,
    label: "erroe_content_data",
    data: data?.qc_data?.[0]?.error_content_data || "-",
  },
  freelancer_project_data_id: {
    display: false,
    label: "freelancer_project_data_id",
    data: data?.freelancer_project_data_id || "-",
  },
  user_id: {
    display: false,
    label: "user_id",
    data: data?.user_id || "-",
  },
});

const qcDataColumns = [
  {
    id: "id",
    label: "Data ID",
    styles: { maxWidth: "150px", width: "50px" },
    align: "center",
  },
  {
    id: "field_name",
    label: "Field Name",
    align: "left",
  },

  {
    id: "erroe_content_data",
    label: "Error Content Data",
    // styles: { maxWidth: "120px", width: "120px" },
    align: "left",
    sort: false,
  },
  {
    label: "Actions",
    align: "center",
    styles: { maxWidth: "160px", width: "160px" },
  },
];

const QCDataTable = ({ page, dataCount, dataPerPage, data, parentId }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { actions } = qcManagementSlice;

  const qcContext = useContext(QCManagementContext);

  const [rowData, setRowData] = useState([]);

  useEffect(() => {
    const formattedData = data?.map((d, i) => getFormattedQCData(d));

    setRowData(formattedData);
  }, [data]);

  return (
    <div>
      <Box
        sx={{
          fontWeight: "bold",
          fontSize: "18px",
          textDecoration: "underline",
        }}
      >
        QC Data List View
      </Box>
      <Box sx={{ border: "1px #ccc solid", marginTop: "10px" }}>
        <TableCustomServer
          rowData={rowData}
          viewAction={(row) => {
            dispatch(
              actions.selectedQCListFieldFetched({
                parentId,
                ...row,
              })
            );
            qcContext.openViewQCDataFieldsDialog(parentId, row.id.data);
          }}
          show={true}
          showViewButton={true}
          showEditButton={false}
          showDeleteButton={false}
          showMoreButton={false}
          page={page}
          dataCount={dataCount}
          dataPerPage={dataPerPage}
          // handleRequestSort={(currentKey, order) => {
          //   const isAsc = order === "asc";
          //   dispatch(
          //     actions.setSortConfig({
          //       key: currentKey,
          //       order: isAsc ? "desc" : "asc",
          //     })
          //   );
          // }}
          // handleSetPage={(newPage) => {
          //   dispatch(
          //     actions.setPageConfigData({
          //       type: "SET_PAGE",
          //       data: newPage,
          //     })
          //   );
          // }}
          // handleNoOfRowsPerPage={(value) => {
          //   dispatch(
          //     actions.setPageConfigData({
          //       type: "SET_DATA_PER_PAGE",
          //       data: parseInt(value, 10),
          //     })
          //   );
          //   dispatch(actions.setPageConfigData({ type: "SET_PAGE", data: 0 }));
          // }}
          columnsConfig={qcDataColumns}
          numCols={qcDataColumns.length}
        />
      </Box>
    </div>
  );
};

export default QCDataTable;
