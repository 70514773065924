import AddIcon from "@mui/icons-material/Add";
import CachedIcon from "@mui/icons-material/Cached";
import ClearIcon from "@mui/icons-material/Clear";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import DownloadIcon from "@mui/icons-material/Download";
import SearchIcon from "@mui/icons-material/Search";

import InfoIcon from "@mui/icons-material/Info";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import {
  Button,
  IconButton,
  InputAdornment,
  Popover,
  Toolbar,
  Tooltip,
  Typography
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { useDebouncyEffect } from "use-debouncy";

const EnhancedTableToolbar = ({
  borderTop = "1px solid rgba(224, 224, 224, 1)",
  title,
  tooltipTitle,
  btnTitle,
  circularLoader,
  btnHandler,
  handlerDropDown,
  handlerBulkDropDown,
  refreshWhenWholeFilterChange = false,
  filter,
  refreshHandler,
  extrabtn,
  renderBeforeSearch,
  showbackBtn = true,
  showAdd = true,
  showReload = true,
  showTopMessage = false,
  topMessage,
  showSearch = false,
  shouldGetDataOnSearchInput = true,
  showHelpMenu = false,
  showDropDown = false,
  tooltipHelp = "",
  helpContent,
  showExportBtn = false,
  showExportTooltipTitle = "",
  showExportTitle = "",
  showExportBtnHandler,
  showCommonBtn = false,
  btnCommonTitle = "",
  commonBtnClick = () => { },
  handleBack,
  searchConfig: { searchKeys = [], filterValue = "", setSearchConfig } = {},
}) => {
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openHelpMenu = Boolean(anchorEl);
  const id = openHelpMenu ? "simple-popover-" + title : undefined;

  useDebouncyEffect(
    () => (shouldGetDataOnSearchInput ? refreshHandler() : undefined),
    800,
    [refreshWhenWholeFilterChange ? filter : filterValue]
  );


  const changeFilterHandler = (value) => {
    const dataFilter = {
      search: {
        keys: searchKeys,
        keyword: value,
      },
    };
    setSearchConfig(dataFilter);
  };

  // const [selectedValue, setSelectedValue] = React.useState("");
  // // Add your options data here if needed
  // const options = ["Option 1", "Option 2", "Option 3"];

  // // Define a function to handle the change of the selected value
  // const handleChange = (event) => {
  //   setSelectedValue(event.target.value);
  // };

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => {
    setDropdownOpen((prevState) => !prevState);
  };

  return (
    <>
      {Boolean(showTopMessage) && topMessage}

      <Toolbar
        sx={{
          paddingLeft: (theme) => theme.spacing(2),
          paddingRight: (theme) => theme.spacing(1),
          borderTop: borderTop,
        }}
      >
        {Boolean(showbackBtn) && (
          <Box
            onClick={handleBack ? handleBack : () => navigate(-1)}
            sx={{
              padding: "0px 10px",
              fontSize: "1.4rem",
              cursor: "pointer",
            }}
          >
            <i className="fas fa-arrow-left" style={{ color: "#000" }}></i>
          </Box>
        )}
        <Typography
          sx={{
            fontWeight: 500,
            flex: "1 1 100%",
            fontSize: "1.2rem",
            display: "flex",
            alignItems: "center",
          }}
          variant="h6"
          id="tableTitle"
          component="div"
          noWrap={true}
        >
          <span>{title} </span>
          {Boolean(showHelpMenu) && (
            <Tooltip disableInteractive={true} arrow title={tooltipHelp}>
              <IconButton
                sx={{ padding: "2px" }}
                disableRipple
                onClick={handleClick}
              >
                <InfoIcon />
              </IconButton>
            </Tooltip>
          )}
          {circularLoader}
        </Typography>

        {renderBeforeSearch}

        {Boolean(showSearch) && (
          <Col sm={6} lg={4} style={{ padding: "10px", marginTop: "8px" }}>
            <TextField
              fullWidth={true}
              sx={{
                "& legend": {
                  width: "0px",
                },
              }}
              // className={classes.input}
              // inputProps={{ style: { padding: "11px 4px" } }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: filterValue && (
                  <InputAdornment position="end">
                    <IconButton
                      size="small"
                      onClick={() => changeFilterHandler("")}
                    >
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ),
                autoComplete: "off",
              }}
              inputProps={{
                style: {
                  padding: "6px 14px",
                },
              }}
              // value={filterValue}
              variant="outlined"
              // sx={{
              //   border:"1px #aeaeae solid",
              //   borderRadius:"4px"
              // }}
              onChange={(e) => {
                const { value } = e.target;
                changeFilterHandler(value);
              }}
              name="searchText"
              placeholder="Search"
            />

            {/* <Form.Control
              type="text"
              value={filterValue}
              style={{ borderLeft: "0px solid #fff" }}
              onChange={(e) => {
                const value = e.target.value;
                const dataFilter = {
                  search: {
                    keys: searchKeys,
                    keyword: value,
                  },
                };
                setSearchConfig(dataFilter);
              }}
              name="searchText"
              placeholder="Search"
            /> */}
          </Col>
        )}

        {extrabtn}

        {Boolean(showReload) && (
          <Tooltip disableInteractive={true} arrow title="Fetch New Data">
            <Button
              size="medium"
              variant="contained"
              style={{
                backgroundColor: "#000",
                color: "#fff",
                minWidth: "auto",
              }}
              onClick={refreshHandler}
              startIcon={<CachedIcon />}
            >
              Reload
            </Button>
          </Tooltip>
        )}

        {Boolean(showAdd) && (
          <Tooltip disableInteractive={true} arrow title={tooltipTitle}>
            <Button
              size="medium"
              color="primary"
              variant="contained"
              style={{ color: "#fff", marginLeft: "10px", minWidth: "auto" }}
              onClick={btnHandler}
              startIcon={<AddIcon />}
            >
              {/* <AddCircleOutlineIcon /> */}

              {btnTitle}
            </Button>
          </Tooltip>
        )}

        {Boolean(showDropDown) && (
          <Tooltip disableInteractive={true} arrow title={tooltipTitle}>
            <div className="btn-group-dropdown">
              <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                <DropdownToggle>
                  {" "}
                  <AddIcon /> ADD
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={handlerDropDown}>
                    <RemoveRedEyeIcon /> Single Level Add freelancers
                  </DropdownItem>

                  <DropdownItem onClick={handlerBulkDropDown}>
                    <RemoveRedEyeIcon /> Bulk freelancers upload
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
          </Tooltip>
        )}

        {Boolean(showHelpMenu) && (
          <Popover
            id={id}
            open={openHelpMenu}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            {helpContent}
          </Popover>
        )}

        {Boolean(showExportBtn) && (
          <Tooltip
            disableInteractive={true}
            arrow
            title={showExportTooltipTitle}
          >
            <Button
              size="medium"
              color="warning"
              variant="contained"
              style={{ color: "#fff", marginLeft: "10px", minWidth: "auto" }}
              onClick={showExportBtnHandler}
              startIcon={<CloudDownloadIcon />}
            >
              {/* <AddCircleOutlineIcon /> */}

              {showExportTitle}
            </Button>
          </Tooltip>
        )}

        {Boolean(showCommonBtn) && (
          <Tooltip disableInteractive={true} arrow title={btnCommonTitle}>
            <Button
              size="medium"
              variant="contained"
              style={{
                backgroundColor: "#000",
                color: "#fff",
                minWidth: "auto",
                textWrap: "noWrap",
              }}
              onClick={commonBtnClick}
              startIcon={<DownloadIcon />}
            >
              {btnCommonTitle}
            </Button>
          </Tooltip>
        )}
      </Toolbar>
    </>
  );
};

export default EnhancedTableToolbar;
