import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allQuery: [],
  selectedQuery: null,
  selectedQueryProfile: null,
  filter: {},
  isLoading: false,
  page: 0,
  dataCount: 0,
  dataPerPage: 10,
  sort: {
    updatedAt: "desc",
  },
};

export const querySlice = createSlice({
  name: "queryManagement",
  initialState: initialState,
  reducers: {
    setAllQueryData: (state, action) => {
      // console.log('actionquery',action);
      state.allQuery = action.payload;
    },
    queryFetchedData: (state, action) => {
      state.selectedQuery = action.payload;
    },
    setFilterData: (state, action) => {
      state.filter = action.payload.data;
    },
    removeSelectedQuery: (state) => {
      state.selectedQuery = null;
    },
    setSelectedQuery: (state, action) => {
      state.selectedQuery = action.payload;
    },
    setQueryProfileData: (state, action) => {
      state.selectedQueryProfile = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setPageConfigData: (state, action) => {
      switch (action.payload.type) {
        case "SET_PAGE":
          state.page = action.payload.data;
          break;
        case "SET_DATA_COUNT":
          state.dataCount = action.payload.data;
          break;
        case "SET_IS_LOADING":
          state.isLoading = action.payload.data;
          break;
        case "SET_DATA_PER_PAGE":
          state.dataPerPage = action.payload.data;
          break;
        default:
          break;
      }
    },
  },
});
