import { Box, Button } from "@mui/material";
import React from "react";
import CustomReactSelect from "src/helper/CustomSelect/CustomReactSelect";
import BootstrapButton from "src/helper/UI/Button/BootstrapButton";

const statusOptions = [];

const QCDataSmartCheckFilter = ({ data }) => {
  const [status, setStatus] = React.useState("");
  return (
    <Box>
      <Box
        sx={{
          fontWeight: "bold",
          fontSize: "18px",
          textDecoration: "underline",
        }}
      >
        Smart Check Fields
      </Box>
      <Box
        sx={{
          //   display: "grid",
          padding: "10px 0px",
        }}
      >
        <CustomReactSelect
          menuPortalTarget={document.querySelector("body")}
          options={statusOptions.map((d) => ({
            label: d.label,
            value: d.value,
          }))}
          styles={{
            menuPortal: (base) => ({ ...base, zIndex: 1301 }),
          }}
          sortOptions={false}
          value={status || ""}
          classNamePrefix={"reactselect-select"}
          onChange={(data) => setStatus(data.value)}
          isSearchable={true}
          placeholder="Select Permission Code"
          // className='form-FormControl'
        />
        <Box
          sx={{
            marginTop: "14px",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            size="medium"
            color="primary"
            variant="contained"
            style={{ color: "#fff", marginLeft: "10px", minWidth: "auto" }}
            onClick={() => {}}
          >
            {"Smart Check"}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default QCDataSmartCheckFilter;
