import React, { createContext } from "react";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import PaymentManagement from './PaymentManagement';
import { paymentManageSlice } from "src/store/slice/paymentManageSlice";
import ViewPaymentDetailDialog from "./components/ViewDialog/ViewPaymentDetailDialog";
export const PaymentManagementContext = createContext(null);

const routesView = ["/payment-management/:id/view"];
const PaymentManagementRoute = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
  
    const { actions } = paymentManageSlice;
    const dispatch = useDispatch();
  
    const UIEvents = {
      addPayment: () => {
        navigate(`/payment-management`);
      },
      openViewPaymentDialog: (id) => {
        navigate(`/payment-management/${id}/view`);
      },
    };
  
    return (
      <PaymentManagementContext.Provider value={UIEvents}>
        {/* {pathname === "/qc-management/add" && (
          <RoleAdd
            show={(pathname === "/qc-management/add") != null}
            onHide={() => {
              navigate("/qc-management");
            }}
          />
        )} */}
  
        {/* <RoleAdd
          show={
            routesView.find((route) => matchPath(route, pathname)) ? true : false
          }
          isForEdit={true}
          onHide={() => {
            dispatch(actions.roleFetched({}));
            navigate("/qc-management");
          }}
        /> */}
        {/* <RoleDelete
          show={
            routesdelete.find((route) => matchPath(route, pathname))
              ? true
              : false
          }
          onHide={() => {
            dispatch(actions.roleFetched({}));
            navigate("/qc-management");
          }}
        /> */}
        {/* {routesViewData.find((route) => matchPath(route, pathname)) ? (
          <ApprovedPayment />
        ) : routesViewDataSmartCheck.find((route) =>
            matchPath(route, pathname)
          ) ? (
          <QCDataSmartCheck />
        ): ( */}
        <ViewPaymentDetailDialog
        show={
          routesView.find((route) => matchPath(route, pathname)) ? true : false
        }
        onHide={() => {
        //   dispatch(actions.roleFetched({}));
          navigate("/payment-management");
        }}
      />
          <PaymentManagement />
         {/* )} */}
      </PaymentManagementContext.Provider>
    );
}

export default PaymentManagementRoute