import axios from "axios";
import { HOST_URL } from "./dataBank";


export const getQCDataList = (data) => {
  return axios.post(HOST_URL + "/getQCDataList", data);
};
export const getQCData = (id) => {
  return axios.get(HOST_URL + "/getQCData/" + id);
};
export const getQCProjectDataList = (data) => {
  return axios.post(HOST_URL + "/getQCProjectDataList", data);
};
export const getprojectData = (data) => {
  return axios.post(HOST_URL + "/projectData", data);
};
