import React, { useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Button, Modal } from "react-bootstrap";
import { useTheme } from "@mui/material";
import { generalSlice } from "src/store/slice/generalSlice";
import BootstrapButton from "../UI/Button/BootstrapButton";

const ConfirmationModal = () => {
  const dispatch = useDispatch();
  const { actions } = generalSlice;
  const theme = useTheme();

  const { confirmationConfig } = useSelector(
    (state) => ({
      confirmationConfig: state.general.confirmationConfig || {},
    }),
    shallowEqual
  );

  const {
    show,
    type,

    actionConfirm,
    cancelLabel,
    confirmLabel,
    message,
    title,
    confirmLabelWhenSubmitting,
    actionAfterSuccess,
  } = confirmationConfig;
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleCancel = () => {
    dispatch(actions.setConfirmationConfig(null));
  };

  const handleConfirm = () => {
    const prom = actionConfirm();

    if (prom) {
      setIsSubmitting(true);
      prom
        .then(() => {
          if (actionAfterSuccess) {
            actionAfterSuccess();
          }
        })
        .finally(() => {
          handleCancel();
          setIsSubmitting(false);
        });
    }
  };

  return (
    <Modal
      show={show}
      backdrop="static"
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header
        style={{
          backgroundColor:
            type === "info"
              ? theme.palette.warning.light
              : theme.palette.error.main,
        }}
      >
        <Modal.Title
          id="example-modal-sizes-title-lg"
          style={{ color: "#fff" }}
        >
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span style={{ fontSize: "1.2rem" }}>{message}</span>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={handleCancel}
          style={{ marginLeft: "10px" }}
          disabled={isSubmitting}
        >
          {cancelLabel}
        </Button>

        <BootstrapButton
          variant={type === "info" ? "warning" : "danger"}
          type="submit"
          label={confirmLabel}
          labelWhenSubmitting={
            confirmLabelWhenSubmitting
              ? confirmLabelWhenSubmitting
              : confirmLabel
          }
          isSubmitting={isSubmitting}
          onClick={handleConfirm}
          style={{ marginLeft: "10px" }}
          disabled={isSubmitting}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;
