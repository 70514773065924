import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allExtensionManagement: [],
  selectedExtension: null,
  selectedSlot: [],
  filter: {},
  isLoading: false,
  page: 0,
  dataCount: 0,
  dataPerPage: 10,
  sort: {
    updatedAt: "desc",
  },
};

export const extensionManagementSlice = createSlice({
  name: "extensionManagement",
  initialState: initialState,
  reducers: {
    setAllextensionManagementData: (state, action) => {
      state.allExtensionManagement = action.payload;
    },
    queryFetchedData: (state, action) => {
      state.selectedAuditLogs = action.payload;
    },
    setFilterData: (state, action) => {
      state.filter = action.payload.data;
    },
    setSelectedExtension: (state, action) => {
      state.selectedExtension = action.payload;
    },
    removeSelectedExtension: (state) => {
      state.selectedExtension = null;
    },
    setSlot: (state, action) => {
      state.selectedSlot = action.payload;
    },
    removeSlot: (state) => {
      state.selectedSlot = null;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setPageConfigData: (state, action) => {
      switch (action.payload.type) {
        case "SET_PAGE":
          state.page = action.payload.data;
          break;
        case "SET_DATA_COUNT":
          state.dataCount = action.payload.data;
          break;
        case "SET_IS_LOADING":
          state.isLoading = action.payload.data;
          break;
        case "SET_DATA_PER_PAGE":
          state.dataPerPage = action.payload.data;
          break;
        default:
          break;
      }
    },
  },
});
