import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allPermissions: [],
  selectedPermission: null,
  filter: {},
  isLoading: false,
};

export const permissionsSlice = createSlice({
  name: "permissions",
  initialState: initialState,
  reducers: {
    setAllPermissions: (state, action) => {
      state.allPermissions = action.payload;
    },
    permissionFetched: (state, action) => {
      state.selectedPermission = action.payload;
    },
    removeSelectedPermission: (state) => {
      state.selectedPermission = null;
    },
    setFilter: (state, action) => {
      state.filter = action.payload.data;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload.data;
    },
  },
});
