import { Box, Divider, Paper } from "@mui/material";
import React, { useContext } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import EnhancedTableToolbar from "src/helper/EnhancedTableToolbar/EnhancedTableToolbar";
import { qcManagementSlice } from "src/store/slice/qcManagement";
import { QCManagementContext } from "src/views/QCManagement/QCManagementRoute";
import { Col, Row } from "react-bootstrap";
import QCDataSmartCheckTable from "./components/QCDataSmartCheckTable";
import QCDataSmartCheckFilter from "./components/QCDataSmartCheckFilter";

const QCDataSmartCheck = () => {
  const { actions } = qcManagementSlice;
  const dispatch = useDispatch();
  const context = useContext(QCManagementContext);

  // const [status, setStatus] = useState("");

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { allQC, filter } = useSelector(
    (state) => ({
      allQC: state.qcManagement.allQC || [],
      filter: state.qcManagement.filter || [],
    }),
    shallowEqual
  );

  return (
    <>
      <Paper sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
        <EnhancedTableToolbar
          title="View QC Data"
          // btnHandler={() => context.addRole()}
          //   refreshHandler={getAllData}
          // circularLoader={
          //   isLoading && (
          //     <span style={{ marginLeft: "5px" }}>
          //       <Spinner animation="border" />
          //     </span>
          //   )
          // }
          showAdd={false}
          shouldGetDataOnSearchInput={false}
        />
      </Paper>
      <Paper
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          marginTop: "10px",
        }}
      >
        <Box
          sx={{
            width: "100%",
            justifyContent: "space-between",
            display: "flex",
            alignItems: "center",
            padding: "10px 20px",
            fontWeight: "bold",
            fontSize: "20px",
          }}
        >
          <Box>QC ID:- </Box>
          <Box>Slot ID:- </Box>
          <Box>Username:- </Box>
          <Box>Project Name: </Box>
        </Box>
      </Paper>

      <Row style={{ marginTop: "10px" }}>
        <Col md={3}>
          <Paper
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              padding: "16px",
            }}
          >
            <QCDataSmartCheckFilter />
          </Paper>
        </Col>
        <Col md={9} style={{ paddingLeft: "0" }}>
          <Paper
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              padding: "16px",
            }}
          >
            <QCDataSmartCheckTable />
          </Paper>
        </Col>
      </Row>
    </>
  );
};

export default QCDataSmartCheck;
