import React, { useEffect } from "react";
import RaisedPaymentTable from "./components/RaisedPaymentTable";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { paymentManageSlice } from "src/store/slice/paymentManageSlice";

const RaisedPayment = () => {
  const { allPaymentRecord, page, dataCount, dataPerPage } = useSelector(
    (state) => ({
      allPaymentRecord: state.paymentManage?.raised?.allPaymentRecord || [],
      page: state.paymentManage?.raised?.page || 0,
      dataCount: state.paymentManage?.raised?.dataCount || 0,
      dataPerPage: state.paymentManage?.raised?.dataPerPage || 25,
    }),
    shallowEqual
  );
  const { actions } = paymentManageSlice;
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(actions.setAllRaisedPayment([]));
    };
  },[]);

  return (
    <RaisedPaymentTable
      allPaymentRecord={allPaymentRecord}
      page={page}
      dataCount={dataCount}
      dataPerPage={dataPerPage}
    />
  );
};

export default RaisedPayment;
