import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allPayment: [],
  selectedPayment: null,
  filter: {},
  selectedPaymentListData: null,
  page: 0,
  dataCount: 0,
  dataPerPage: 25,
  sort: {
    updatedAt: "desc",
  },
  approved: {
    allPaymentRecord: [],
    page: 0,
    dataCount: 0,
    dataPerPage: 25,
  },
  raised: {
    allPaymentRecord: [],
    page: 0,
    dataCount: 0,
    dataPerPage: 25,
  },
  payed: {
    allPaymentRecord: [],
    page: 0,
    dataCount: 0,
    dataPerPage: 25,
  },
  isLoading: false,
};

export const paymentManageSlice = createSlice({
  name: "paymentManage",
  initialState: initialState,
  reducers: {
    setAllPayment: (state, action) => {
      state.allPayment = action.payload;
    },
    paymentFetched: (state, action) => {
      state.selectedPayment = action.payload;
    },
    selectedPaymentListDataFetched: (state, action) => {
      state.selectedPaymentListData = action.payload;
    },
    setPageConfigData: (state, action) => {
      switch (action.payload.type) {
        case "SET_PAGE":
          state.page = action.payload.data;
          break;
        case "SET_DATA_COUNT":
          state.dataCount = action.payload.data;
          break;
        case "SET_DATA_PER_PAGE":
          state.dataPerPage = action.payload.data;
          break;
        default:
          break;
      }
    },
    setFilter: (state, action) => {
      state.filter = {
        ...state.filter,
        ...action.payload,
      };
    },

    setSortConfig: (state, action) => {
      state.sort = {
        [action.payload.key]: action.payload.order,
      };
    },

    setAllApprovedPayment: (state, action) => {
      state.approved.allPaymentRecord = action.payload;
    },
    setApprovedPaymentPageConfigData: (state, action) => {
      switch (action.payload.type) {
        case "SET_PAGE":
          state.approved.page = action.payload.data;
          break;
        case "SET_DATA_COUNT":
          state.approved.dataCount = action.payload.data;
          break;
        case "SET_DATA_PER_PAGE":
          state.approved.dataPerPage = action.payload.data;
          break;
        default:
          break;
      }
    },
    setAllRaisedPayment: (state, action) => {
      state.raised.allPaymentRecord = action.payload;
    },
    setRaisedPaymentPageConfigData: (state, action) => {
      switch (action.payload.type) {
        case "SET_PAGE":
          state.raised.page = action.payload.data;
          break;
        case "SET_DATA_COUNT":
          state.raised.dataCount = action.payload.data;
          break;
        case "SET_DATA_PER_PAGE":
          state.raised.dataPerPage = action.payload.data;
          break;
        default:
          break;
      }
    },
    setAllPayedPayment: (state, action) => {
      state.payed.allPaymentRecord = action.payload;
    },
    setPayedPaymentPageConfigData: (state, action) => {
      switch (action.payload.type) {
        case "SET_PAGE":
          state.payed.page = action.payload.data;
          break;
        case "SET_DATA_COUNT":
          state.payed.dataCount = action.payload.data;
          break;
        case "SET_DATA_PER_PAGE":
          state.payed.dataPerPage = action.payload.data;
          break;
        default:
          break;
      }
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});
