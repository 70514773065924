import { Box, Divider, Paper, Tab, Tabs } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getPaymentRequestList } from "src/apis/paymentManagement";
import EnhancedTableToolbar from "src/helper/EnhancedTableToolbar/EnhancedTableToolbar";
import { paymentManageSlice } from "src/store/slice/paymentManageSlice";
import QCFilter from "../QCManagement/components/Filter/QCFilter";
import ApprovedPayment from "./components/ApprovedPayment/ApprovedPayment";
import PayedTickit from "./components/PayedTickit/PayedTickit";
import RaisedPayment from "./components/RaisedPayment/RaisedPayment";
import { PaymentManagementContext } from "./PaymentManagementRoute";
import { Spinner } from "react-bootstrap";

const searchKeys = [];

const getStatus = {
  0: [1, 3],
  1: [2],
  2: [4],
};

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  console.log(value, index);

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const PaymentManagement = () => {
  const { actions } = paymentManageSlice;
  const dispatch = useDispatch();
  const context = useContext(PaymentManagementContext);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { filter, isLoading } = useSelector(
    (state) => ({
      filter: state.paymentManage.filter || [],
      isLoading: state.paymentManage.isLoading,
    }),
    shallowEqual
  );
  const getAllData = () => {
    // console.log(filter?.data?.search?.keyword);
    // if (value) {
    dispatch(actions.setIsLoading(true));
    getPaymentRequestList({
      search: filter?.data?.search?.keyword || "",
      page_no: 1,
      page_record: 25,
      date: null,
      status: getStatus[value],
    })
      .then((res) => {
        console.log(res?.data?.data);
        switch (value) {
          case 0:
            dispatch(
              actions.setAllRaisedPayment(res?.data?.data?.payment_data?.rows)
            );
            dispatch(
              actions.setPageConfigData({
                type: "SET_DATA_COUNT",
                data: res?.data?.data?.payment_data?.count || 0,
              })
            );
            break;
          case 1:
            dispatch(
              actions.setAllApprovedPayment(res?.data?.data?.payment_data?.rows)
            );
            dispatch(
              actions.setPageConfigData({
                type: "SET_DATA_COUNT",
                data: res?.data?.data?.payment_data?.count || 0,
              })
            );
            break;
          case 2:
            dispatch(
              actions.setAllPayedPayment(res?.data?.data?.payment_data?.rows)
            );
            dispatch(
              actions.setPageConfigData({
                type: "SET_DATA_COUNT",
                data: res?.data?.data?.payment_data?.count || 0,
              })
            );
            break;
          default:
            break;
        }
        // dispatch(actions.setAllQC(res?.data?.data?.qc_data?.rows));
      })
      .catch((data) => console.log(data.response))
      .finally(() => {
        dispatch(actions.setIsLoading(false));
      });
    // }
  };

  useEffect(() => {
    getAllData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, value]);

  return (
    <Paper sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <EnhancedTableToolbar
        title="Payment Management"
        btnHandler={() => context.addRole()}
        refreshHandler={getAllData}
        circularLoader={
          isLoading && (
            <span style={{ marginLeft: "10px" }}>
              <Spinner animation="border" />
            </span>
          )
        }
        showAdd={false}
        btnTitle="ADD"
        shouldGetDataOnSearchInput={false}
      />
      <Divider />

      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs"
            sx={{ justifyContent: "space-evenly" }}
          >
            <Tab label="Payment Tickit Raised" {...a11yProps(0)} />
            <Tab label="Payment Tickit Approved" {...a11yProps(1)} />
            <Tab label="Paied Tickit List" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <QCFilter
            searchConfig={{
              searchKeys,
              filterValue: filter?.data?.search?.keyword || "",
              setSearchConfig: (data) => {
                dispatch(actions.setFilter({ data }));
              },
            }}
          />
          <RaisedPayment />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <QCFilter
            searchConfig={{
              searchKeys,
              filterValue: filter?.data?.search?.keyword || "",
              setSearchConfig: (data) => {
                dispatch(actions.setFilter({ data }));
              },
            }}
          />
          <ApprovedPayment />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <QCFilter
            searchConfig={{
              searchKeys,
              filterValue: filter?.data?.search?.keyword || "",
              setSearchConfig: (data) => {
                dispatch(actions.setFilter({ data }));
              },
            }}
          />
          <PayedTickit />
        </CustomTabPanel>
      </Box>
    </Paper>
  );
};

export default PaymentManagement;
