import axios from "axios";

const LOGIN_URL = "https://api.databazaar.co/api/v1/login";
const LOGOUT_URL = "https://api.databazaar.co/api/v1/logOut";

export const getUserByToken = () => {
  return new Promise((resolve, reject) => {
    // setTimeout(() => {
    resolve(localStorage.getItem("user") && localStorage.getItem("token"));
    // }, 1000);
  });
};

export async function login(email, password) {
  return axios.post(LOGIN_URL, {
    email_address: email,
    password: password,
  });
}

export async function logout(token) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(
        localStorage.removeItem("user") && localStorage.removeItem("token")
      );
    }, 1000);
  });
  // return axios.get(LOGOUT_URL, {
  //   Authorization: token,
  // });
}
