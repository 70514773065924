import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import { LayoutSplashScreen } from "src/utils/SplashScreen";

const Logout = (props) => {
  const { authToken } = props;
  useEffect(() => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
  }, []);

  return Boolean(authToken) ? (
    <LayoutSplashScreen />
  ) : (
    <Navigate to="/auth/login" replace />
  );
};

export default connect(
  ({ auth }) => ({ authToken: auth.authToken })
  // auth.actions
)(Logout);
