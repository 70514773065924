import { configureStore, combineReducers } from "@reduxjs/toolkit";
import roleReducer from "./slice/roleSlice";
import usersReducer from "./slice/usersSlice";
import { permissionsSlice } from "./slice/permissionsSlice";
import { kycSlice } from "./slice/kycSlice";
import { generalSlice } from "./slice/generalSlice";
import * as auth from "./slice/authRedux";
import { rolesSlice } from "./slice/rolesSlice";
import { userManagementSlice } from "./slice/userManagementSlice";
import { projectManagementSlice } from "./slice/projectManagementSlice";
import { freelancersSlice } from "./slice/freelancerSlice";
import { querySlice } from "./slice/querySlice";
import { auditLogsSlice } from "./slice/auditLogsSlice";
import { extensionManagementSlice } from "./slice/extensionManagementSlice";
import { slotManagementSlice } from "./slice/slotManagementSlice";
import { qcManagementSlice } from "./slice/qcManagement";
import { dataBankSlice } from "./slice/dataBankSlice";
import { paymentManageSlice } from "./slice/paymentManageSlice";
import { contactSlice } from "./slice/contactListSlice";
import { transactionSlice } from "./slice/transactionSlice";
import { freelanceTransactionSlice } from "./slice/freelanceTransaction";
import { callRequestSlice } from "./slice/callRequestSlice";

//   const initialState = {
//     isAuthenticated: false,
//     isLoading: true,
//     user: null
//   };

const rootReducer = combineReducers({
  role: roleReducer,
  users: usersReducer,
  permissions: permissionsSlice.reducer,
  qcManagement: qcManagementSlice.reducer,
  roles: rolesSlice.reducer,
  paymentManage: paymentManageSlice.reducer,
  general: generalSlice.reducer,
  userManagement: userManagementSlice.reducer,
  auth: auth.reducer,
  kyc: kycSlice.reducer,
  projectManagement: projectManagementSlice.reducer,
  freelancers: freelancersSlice.reducer,
  queryManagement: querySlice.reducer,
  auditLogsManagement: auditLogsSlice.reducer,
  extensionManagement: extensionManagementSlice.reducer,
  slotManagement: slotManagementSlice.reducer,
  dataBankManagement: dataBankSlice.reducer,
  contacts: contactSlice.reducer,
  transactions: transactionSlice.reducer,
  freelanceTransactions: freelanceTransactionSlice.reducer,
  callRequest: callRequestSlice.reducer,
});

export default configureStore({
  reducer: rootReducer,
});
