import { Box, Paper } from "@mui/material";
import React, { useContext, useEffect, useMemo } from "react";
import {
  Col,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  Row,
} from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getprojectData } from "src/apis/qcManagement";
import EnhancedTableToolbar from "src/helper/EnhancedTableToolbar/EnhancedTableToolbar";
import { qcManagementSlice } from "src/store/slice/qcManagement";
import { getCurrentDateTime } from "src/utils/utils";
import { rdgPropsConfig } from "src/views/ProjectManagement/components/FormBuilder/DynamicGridLayout";
import { QCManagementContext } from "src/views/QCManagement/QCManagementRoute";
import RGL, { WidthProvider } from "react-grid-layout";

const renderComponent = (config, value, data) => {
  console.log(config);
  switch (config?.label) {
    case "select":
    case "number":
    case "date":
    case "time":
    case "text_field":
      return (
        <Col key={data.project_form_controller_id}>
          <FormGroup>
            <FormLabel style={{ fontWeight: 600 }}>
              {config?.config?.label}
            </FormLabel>
            <FormControl
              type={config.type}
              name={config.i}
              value={value}
              disabled
            />
          </FormGroup>
        </Col>
      );
    case "text_area":
      return (
        <Col key={data.project_form_controller_id}>
          <FormGroup>
            <FormLabel style={{ fontWeight: 600 }}>
              {config?.config?.label}
            </FormLabel>
            <FormControl
              style={{ resize: "none" }}
              type={config.type}
              name={config.i}
              as={config.type}
              value={value}
              // multiple
              readOnly
              disabled
            />
          </FormGroup>
        </Col>
      );
    case "radio":
      return (
        <Col key={data.project_form_controller_id}>
          <FormGroup>
            <FormLabel style={{ fontWeight: 600 }}>
              {config?.config?.label}
            </FormLabel>
            {config?.config?.optionForRadio?.map((d, i) => (
              <Form.Check
                value={1}
                key={i}
                type="radio"
                aria-label={config?.config?.label}
                label={d}
                readOnly
                checked={value === d}
              />
            ))}
          </FormGroup>
        </Col>
      );
    case "checkbox":
      return (
        <Col key={data.project_form_controller_id}>
          <FormGroup>
            <FormLabel style={{ fontWeight: 600 }}>
              {config?.config?.label}
            </FormLabel>
            {config?.config?.optionForRadio?.map((d, i) => (
              <Form.Check
                value={1}
                key={i}
                type="checkbox"
                aria-label={config?.config?.label}
                label={d}
                readOnly
                checked={value === d}
              />
            ))}
          </FormGroup>
        </Col>
      );
    case "switch":
      return (
        <Col key={data.project_form_controller_id}>
          <FormGroup>
            <FormLabel style={{ fontWeight: 600 }}>
              {config?.config?.label}
            </FormLabel>
            <FormControl
              style={{ resize: "none" }}
              type={config.type}
              name={config.i}
              as={config.type}
              value={value}
              // multiple
              readOnly
              disabled
            />
          </FormGroup>
        </Col>
      );
    case "button_group":
      return (
        <Col key={data.project_form_controller_id}>
          <FormGroup>
            <FormLabel style={{ fontWeight: 600 }}>
              {config?.config?.label}
            </FormLabel>
            <Form.Check
              type="switch"
              id={config?.config?.label}
              name={config?.config?.label}
              label=""
              value={value}
              readOnly
            />
          </FormGroup>
        </Col>
      );
    default:
      return <></>;
  }
};

const ReactGridLayout = WidthProvider(RGL);

const getFormattedQCData = (data) => ({
  freelancer: data?.user?.user_name,
  email: data?.user?.email_address,
  mobileNo: data?.user?.phone_number,
  username: data?.user?.user_id,
  count: data?.user_slot?.max_data_assign,
  submissionOn: data?.user_slot?.created_datetime
    ? getCurrentDateTime(new Date(data?.user_slot?.created_datetime))
    : "",
  registeredOn: data?.user?.created_datetime
    ? getCurrentDateTime(new Date(data?.user?.created_datetime))
    : "",
  accurateData: data?.accurate_data,
  result: data?.result,
  payout: data?.payout,
  totalPayout: data?.total_payout,
});

const QCDataRecordView = () => {
  const { actions } = qcManagementSlice;
  const dispatch = useDispatch();
  const context = useContext(QCManagementContext);
  const [formattedData, setFormattedData] = React.useState(null);
  const [layout, setLayout] = React.useState(null);

  // const [status, setStatus] = useState("");

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const {
    allQC,
    selectedQC,
    qcDataView,
    selectedQCListData,
    selectedQCListField,
  } = useSelector(
    (state) => ({
      allQC: state.qcManagement.allQC || [],
      selectedQC: state.qcManagement.selectedQC || {},
      selectedQCListData: state.qcManagement.selectedQCListData || {},
      selectedQCListField: state.qcManagement.selectedQCListField || {},
      qcDataView: state.qcManagement.qcDataView || {},
    }),
    shallowEqual
  );

  //   useEffect(() => {
  //     const data = getFormattedQCData({
  //       ...selectedQCListData,
  //       ...selectedQC?.data,
  //     });
  //     setFormattedData(data);
  //   }, [selectedQC, selectedQCListData]);

  const getAllData = () => {
    getprojectData({
      freelancer_project_data_id:
        selectedQCListField?.freelancer_project_data_id?.data,
      qc_management_project_data_id: selectedQCListField?.id?.data,
      user_id: selectedQCListField?.user_id?.data,
    })
      .then((res) => {
        // console.log(res);
        setFormattedData(res?.data?.data?.data);
        // dispatch(actions.selectedQCListDataFetched(res?.data?.data?.qc_data));
      })
      .catch((data) => console.log(data.response));
  };

  useEffect(() => {
    const data = formattedData?.project_form_controllers?.map((data) =>
      data?.json_data ? JSON.parse(data?.json_data) : {}
    );

    setLayout(data);
  }, [formattedData]);

  //   console.log("selectedQCListData", selectedQCListData);

  useEffect(() => {
    if (selectedQCListField) {
      getAllData();
    } else {
      //   console.log("else", selectedQCListField);
    }
  }, [selectedQCListField]);

  return (
    <>
      <Paper sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
        <EnhancedTableToolbar
          title="View QC Data"
          // btnHandler={() => context.addRole()}
          //   refreshHandler={getAllData}
          // circularLoader={
          //   isLoading && (
          //     <span style={{ marginLeft: "5px" }}>
          //       <Spinner animation="border" />
          //     </span>
          //   )
          // }
          showAdd={false}
          shouldGetDataOnSearchInput={false}
        />
      </Paper>
      <Paper
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          marginTop: "10px",
        }}
      >
        <Box
          sx={{
            width: "100%",
            justifyContent: "space-between",
            display: "flex",
            alignItems: "center",
            padding: "10px 20px",
            fontWeight: "bold",
            fontSize: "20px",
          }}
        >
          <Box>QC ID:- {selectedQCListData?.qc_management_id || ""}</Box>
          <Box>Project Name: {selectedQCListData?.project_name || ""}</Box>
        </Box>
      </Paper>

      <Row style={{ marginTop: "10px" }}>
        <Col md={12}>
          <Paper
            sx={{
              height: "100%",
              padding: "16px",
              display: "grid",
              gridTemplateColumns: `1fr 1fr`,
              gap: "20px",
              //   gridTemplateRows: `repeat(${rdgPropsConfig.rows},1fr)`,
            }}
          >
            <Box
              sx={{
                height: "680px",
                flex: "1",
                width: "100%",
                border: "1px #111 solid",
                // overflow: "auto",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  // alignItems: "center",
                  height: "100%",
                  width: "100%",
                  overflow: "auto",
                  backgroundColor: "#333",
                  // padding: "5px",
                }}
              >
                <embed
                  style={{
                    width: "100%",
                  }}
                  src={
                    (formattedData?.data_sheet_url ||
                      "https://www.africau.edu/images/default/sample.pdf") +
                    "#toolbar=1"
                  }
                />
              </div>
            </Box>
            <Box>
              {formattedData?.project_form_controllers?.map((data) =>
                renderComponent(
                  JSON.parse(data?.json_data),
                  data?.value_data,
                  data
                )
              )}
            </Box>
          </Paper>
        </Col>
      </Row>
    </>
  );
};

export default QCDataRecordView;
