import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allKyc: [],
  selectedKyc: null,
  filter: {},
  isLoading: false,
};

export const kycSlice = createSlice({
  name: "KYC",
  initialState: initialState,
  reducers: {
    setAllKyc: (state, action) => {
      state.allKyc = action.payload;
    },
    kycFetched: (state, action) => {
      state.selectedKyc = action.payload;
    },
    removeSelectedkyc: (state) => {
      state.selectedKyc = null;
    },
    setFilter: (state, action) => {
      state.filter = action.payload.data;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});
