import { createContext } from "react";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import QCDataMenualCheck from "./components/QCViews/QCDataMenualCheck/QCDataMenualCheck";
import QCDataRecordView from "./components/QCViews/QCDataRecordView/QCDataRecordView";
import QCDataSmartCheck from "./components/QCViews/QCDataSmartCheck/QCDataSmartCheck";
import QCDataView from "./components/QCViews/QCDataView/QCDataView";
import QCManagement from "./QCManagement";

const routesdelete = ["/qc-management/:id/delete"];
const routesView = ["/qc-management/:id/view"];
const routesViewData = ["/qc-management/:id/view-data"];
const routesViewDataForm = ["/qc-management/:id/view-data/:id/data"];
const routesViewDataSmartCheck = ["/qc-management/:id/view-samrt-check-data"];
const routesViewDataMenualCheck = ["/qc-management/:id/view-menual-check-data"];

export const QCManagementContext = createContext(null);

const QCManagementRoute = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();


  const UIEvents = {
    addQC: () => {
      navigate(`/qc-management/add`);
    },
    openViewQCDialog: (id) => {
      navigate(`/qc-management/${id}/view`);
    },
    openViewQCDataDialog: (id) => {
      navigate(`/qc-management/${id}/view-data`);
    },
    openViewQCDataFieldsDialog: (id, fieldId) => {
      navigate(`/qc-management/${id}/view-data/${fieldId}/data`);
    },
    openViewQCDataSmartCheckDialog: (id) => {
      navigate(`/qc-management/${id}/view-samrt-check-data`);
    },
    openViewQCDataMenualCheckDialog: (id) => {
      navigate(`/qc-management/${id}/view-menual-check-data`);
    },
    deleteQC: (id) => {
      navigate(`/qc-management/${id}/delete`);
    },
  };

  return (
    <QCManagementContext.Provider value={UIEvents}>
      {/* {pathname === "/qc-management/add" && (
        <RoleAdd
          show={(pathname === "/qc-management/add") != null}
          onHide={() => {
            navigate("/qc-management");
          }}
        />
      )} */}

      {/* <RoleAdd
        show={
          routesView.find((route) => matchPath(route, pathname)) ? true : false
        }
        isForEdit={true}
        onHide={() => {
          dispatch(actions.roleFetched({}));
          navigate("/qc-management");
        }}
      /> */}
      {/* <RoleDelete
        show={
          routesdelete.find((route) => matchPath(route, pathname))
            ? true
            : false
        }
        onHide={() => {
          dispatch(actions.roleFetched({}));
          navigate("/qc-management");
        }}
      /> */}
      {routesViewData.find((route) => matchPath(route, pathname)) ? (
        <QCDataView />
      ) : routesViewDataSmartCheck.find((route) =>
        matchPath(route, pathname)
      ) ? (
        <QCDataSmartCheck />
      ) : routesViewDataMenualCheck.find((route) =>
        matchPath(route, pathname)
      ) ? (
        <QCDataMenualCheck />
      ) : routesViewDataForm.find((route) =>
        matchPath(route, pathname)
      ) ? (
        <QCDataRecordView />
      ) : (
        <QCManagement />
      )}
    </QCManagementContext.Provider>
  );
};

export default QCManagementRoute;
