import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allProjects: [],
  allFreelancers: [],
  selectedProject: null,
  selectedExtension: null,
  selectedProjectData: null,
  selectedExtensionConfirm: null,
  selectedExtensionView: null,
  filter: {},
  selectedProjectFormData: {},
  page: 0,
  dataCount: 0,
  dataPerPage: 25,
  projectpage: 1,
  projectDataCount: 0,
  projectIsLoading: false,
  projectDataPerPage: 24,
  modalData: {
    show: false,
    itemData: {},
  },
  sort: {
    updatedAt: "desc",
  },
};

export const projectManagementSlice = createSlice({
  name: "projectManagement",
  initialState: initialState,
  reducers: {
    setProjectData: (state, action) => {
      state.selectedProjectData = action.payload;
    },
    setExtensionData: (state, action) => {
      state.selectedExtension = action.payload;
    },
    setExtensionDataConfirm: (state, action) => {
      state.selectedExtensionConfirm = action.payload;
    },
    setExtensionDataView: (state, action) => {
      state.selectedExtensionView = action.payload;
    },
    setAllProjects: (state, action) => {
      state.allProjects = action.payload;
    },
    setAllFreelancers: (state, action) => {
      state.allFreelancers = action.payload;
    },
    permissionFetched: (state, action) => {
      state.selectedProject = action.payload;
    },
    projectFetched: (state, action) => {
      state.selectedProject = action.payload;
    },
    setProjectFormData: (state, action) => {
      state.selectedProjectFormData = action.payload;
    },
    removeSelectedProject: (state) => {
      state.selectedProject = null;
    },
    setPageConfigData: (state, action) => {
      switch (action.payload.type) {
        case "SET_PAGE":
          state.page = action.payload.data;
          break;
        case "SET_DATA_COUNT":
          state.dataCount = action.payload.data;
          break;
        case "SET_IS_LOADING":
          state.isLoading = action.payload.data;
          break;
        case "SET_DATA_PER_PAGE":
          state.dataPerPage = action.payload.data;
          break;
        default:
          break;
      }
    },
    setPageProjectConfigData: (state, action) => {
      switch (action.payload.type) {
        case "SET_PAGE":
          state.projectpage = action.payload.data;
          break;
        case "SET_DATA_COUNT":
          state.projectDataCount = action.payload.data;
          break;
        case "SET_IS_LOADING":
          state.projectIsLoading = action.payload.data;
          break;
        case "SET_DATA_PER_PAGE":
          state.projectDataPerPage = action.payload.data;
          break;
        default:
          break;
      }
    },
    setFilter: (state, action) => {
      state.filter = {
        ...state.filter,
        ...action.payload,
      };
    },
    closeModel: (state) => {
      state.modalData.show = false;
    },
    openModel: (state) => {
      state.modalData.show = true;
    },
    changeModelData: (state, action) => {
      state.modalData.itemData = action.payload;
    },
    setSortConfig: (state, action) => {
      state.sort = {
        [action.payload.key]: action.payload.order,
      };
    },
  },
});
