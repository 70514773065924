import React from "react";
import {
  CAvatar,
  CBadge,
  CDropdown,
  CDropdownDivider,
  CDropdownHeader,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from "@coreui/react";
import {
  cilBell,
  cilCreditCard,
  cilCommentSquare,
  cilEnvelopeOpen,
  cilFile,
  cilLockLocked,
  cilSettings,
  cilTask,
  cilUser,
} from "@coreui/icons";
import { useNavigate } from "react-router-dom";
import CIcon from "@coreui/icons-react";

import FreelancersImg from "src/assets/freelancer.png";

const AppHeaderDropdown = () => {
  const navigate = useNavigate();
  return (
    <CDropdown variant="nav-item">
      <CDropdownToggle placement="bottom-end" className="py-0" caret={false}>
        <CAvatar src={FreelancersImg} size="md" />
        {/* <img
          src={FreelancersImg}
          style={{
            width: "50px",
            height: "100%",
          }}
          alt={""}
        /> */}
      </CDropdownToggle>
      <CDropdownMenu className="pt-2" placement="bottom-end">
        <CDropdownItem style={{
          cursor: "pointer"
        }} onClick={() => {
          navigate("/")
          localStorage.clear();
          window.location.reload();
        }}>
          <CIcon icon={cilLockLocked} className="me-2" />
          Logout
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  );
};

export default AppHeaderDropdown;
