export function cleanObject(obj) {
  const cloneOb = JSON.parse(JSON.stringify(obj || {}));
  for (let propName in cloneOb) {
    if (
      cloneOb[propName] === null ||
      cloneOb[propName] === undefined ||
      cloneOb[propName] === "" ||
      cloneOb[propName] === "|" ||
      (typeof cloneOb[propName] == "object" &&
        Object.keys(cloneOb[propName]).length === 0)
    ) {
      delete cloneOb[propName];
    }

    if (typeof cloneOb[propName] == "string" && cloneOb[propName] !== "") {
      cloneOb[propName] = cloneOb[propName].trim();
    }
  }
  // console.log(cloneOb);
  return cloneOb || {};
}

export const changeHandlerImageImproved = (e, callback, props = {}) => {
  const { files } = e.target;
  if (files[0]) {
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onloadend = (e) => {
      callback({
        file: props.shouldSetFileArray ? files : files[0],
        url: reader.result,
      });
    };
  }
};
export const getGlobalSearchFilteredData = (allData = [], filter) => {
  if (filter)
    return allData.filter((data) =>
      filter.keys.some((key) => {
        let fieldValue = null;
        if (typeof key === "string" && key.includes(".")) {
          //support key="ob.field"
          fieldValue = Object.byString(data, key);
        } else {
          fieldValue = data?.[key];
        }
        // console.log("fieldValue", fieldValue);
        if (fieldValue) {
          if (typeof fieldValue === "string")
            return fieldValue
              ?.toUpperCase()
              .includes(filter.keyword.toUpperCase());
          else if (Array.isArray(fieldValue)) {
            return fieldValue.some((d) =>
              d.toUpperCase().includes(filter.keyword.toUpperCase())
            );
          }
        }

        return false;
      })
    );

  return allData;
};

export const getCurrentDateTime = (
  dateOb,
  withSeconds = false,
  withTime = true
) => {
  if (withTime)
    return `${(dateOb.getMonth() + 1).toString().padStart(2, "0")}/${dateOb
      .getDate()
      .toString()
      .padStart(2, "0")}/${dateOb.getFullYear()}  ${formatAMPM(
      dateOb,
      withSeconds
    )}`;
  else
    return `${(dateOb.getMonth() + 1).toString().padStart(2, "0")}/${dateOb
      .getDate()
      .toString()
      .padStart(2, "0")}/${dateOb.getFullYear()}`;
};

export const formatAMPM = (dateOb, withSeconds) => {
  let hours = dateOb.getHours();
  let minutes = dateOb.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes.toString().padStart(2, "0");

  if (withSeconds) {
    const seconds = dateOb.getSeconds().toString().padStart(2, "0");
    return hours + ":" + minutes + ":" + seconds + " " + ampm;
  }

  return hours + ":" + minutes + " " + ampm;
};


export const toAbsoluteUrl = pathname => process.env.PUBLIC_URL + pathname;
