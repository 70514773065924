// import React from 'react'
import React, { useContext, useEffect, useState } from 'react'
import { PaymentPayedColumn, getFormattedPaymentPayedData } from './PayedTickitTableConfig';
import { useDispatch } from 'react-redux';
import TableCustomServer from 'src/helper/Table/TableCustomServer';
import { paymentManageSlice } from 'src/store/slice/paymentManageSlice';
import { PaymentManagementContext } from 'src/views/PaymentManagement/PaymentManagementRoute';
const PayedTickitTable = ({
    allPaymentRecord = [],
    count = 0,
    page,
    dataCount,
    dataPerPage,
}) => {

    const dispatch = useDispatch();
    const { actions } = paymentManageSlice;
    const context = useContext(PaymentManagementContext);

  const [rowData, setRowData] = useState(allPaymentRecord);

  useEffect(() => {
    const data = allPaymentRecord?.map((data, i) =>
    getFormattedPaymentPayedData(data)
    );

    setRowData(data);
  }, [allPaymentRecord]);


  return (
    <TableCustomServer
      rowData={rowData}
      editAction={(row) => {
        // qcContext.openViewQCDialog(row.id.user_id);
        // dispatch(actions.userFetched(row));
      }}
      deleteAction={(row) => {
        // qcContext.deleteQC(row.id.user_id);
        // dispatch(actions.userFetched(row));
      }}
      show={true}
      showViewButton={false}
      showEditButton={false}
      showDeleteButton={false}
      page={page}
      dataCount={dataCount}
      dataPerPage={dataPerPage}
      handleRequestSort={(currentKey, order) => {
        const isAsc = order === "asc";
        dispatch(
          actions.setSortConfig({
            key: currentKey,
            order: isAsc ? "desc" : "asc",
          })
        );
      }}
      handleSetPage={(newPage) => {
        dispatch(
          actions.setPageConfigData({
            type: "SET_PAGE",
            data: newPage,
          })
        );
      }}
      handleNoOfRowsPerPage={(value) => {
        dispatch(
          actions.setPageConfigData({
            type: "SET_DATA_PER_PAGE",
            data: parseInt(value, 10),
          })
        );
        dispatch(actions.setPageConfigData({ type: "SET_PAGE", data: 0 }));
      }}
      columnsConfig={PaymentPayedColumn}
      numCols={PaymentPayedColumn.length}
      deleteTooltip="Delete User"
      editTooltip="Edit User"
      showMoreButton={false}
    />
  )
}

export default PayedTickitTable