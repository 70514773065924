import React, { useEffect } from "react";
import ApprovedPaymentTable from "./components/ApprovedPaymentTable";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { paymentManageSlice } from "src/store/slice/paymentManageSlice";

const ApprovedPayment = () => {
  const { allPaymentRecord, page, dataCount, dataPerPage } = useSelector(
    (state) => ({
      allPaymentRecord: state.paymentManage?.approved?.allPaymentRecord || [],
      page: state.paymentManage?.approved?.page || 0,
      dataCount: state.paymentManage?.approved?.dataCount || 0,
      dataPerPage: state.paymentManage?.approved?.dataPerPage || 25,
    }),
    shallowEqual
  );
  const { actions } = paymentManageSlice;
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(actions.setAllApprovedPayment([]));
    };
  },[]);
  return (
    <ApprovedPaymentTable
      allPaymentRecord={allPaymentRecord}
      page={page}
      dataCount={dataCount}
      dataPerPage={dataPerPage}
    />
  );
};

export default ApprovedPayment;
