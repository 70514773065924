import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allSlotManagement: [],
  selectedSlot: null,
  selectedFreelancer: [],
  selectedQC: [],
  selectedProject: [],
  filter: {},
  page: 0,
  dataCount: 0,
  dataPerPage: 10,
  sort: {
    updatedAt: "desc",
  },
};

export const slotManagementSlice = createSlice({
  name: "slotManagement",
  initialState: initialState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setAllSlotManagementData: (state, action) => {
      state.allSlotManagement = action.payload;
    },
    queryFetchedData: (state, action) => {
      state.selectedAuditLogs = action.payload;
    },
    removeSelectedSlot: (state) => {
      state.selectedSlot = null;
    },
    setSelectedSlot: (state, action) => {
      state.selectedSlot = action.payload;
    },
    setFilterData: (state, action) => {
      state.filter = action.payload.data;
    },
    setFreelancers: (state, action) => {
      state.selectedFreelancer = action.payload;
    },
    setProject: (state, action) => {
      state.selectedProject = action.payload;
    },
    setQc: (state, action) => {
      state.selectedQC = action.payload;
    },
    setPageConfigData: (state, action) => {
      switch (action.payload.type) {
        case "SET_PAGE":
          state.page = action.payload.data;
          break;
        case "SET_DATA_COUNT":
          state.dataCount = action.payload.data;
          break;
        case "SET_IS_LOADING":
          state.isLoading = action.payload.data;
          break;
        case "SET_DATA_PER_PAGE":
          state.dataPerPage = action.payload.data;
          break;
        default:
          break;
      }
    },
  },
});

export const { setLoading, setUsersData } = slotManagementSlice.actions;
