import {
  Alert,
  Box,
  CircularProgress,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";

import { Button } from "react-bootstrap";
import { login } from "src/apis/login";
import { actionTypes } from "src/store/slice/authRedux";
import { generalSlice } from "src/store/slice/generalSlice";

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    // .email("Wrong email format")
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Required field"),
  password: Yup.string()
    .min(6, "Minimum 6 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Required field"),
});

const Login = () => {
  const { actions: generalActions } = generalSlice;
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  // const auth = useAuth()

  // useEffect(() => {
  //   console.log();
  //   if (getUserByToken()) {
  //     navigate("/");
  //   }
  // }, []);

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      login(values.email, values.password)
        .then(({ data }) => {
          disableLoading();
          localStorage.setItem("token", data?.data?.token);
          localStorage.setItem("user", JSON.stringify(data?.data?.user));
          dispatch({ type: actionTypes.Login, payload: { data: data?.data } });
        })
        .catch((e) => {
          disableLoading();
          setSubmitting(false);
          dispatch(generalActions.pushNewAlert({
            show: true,
            heading: "Error",
            message: e.response?.data?.message,
            type: "danger",
          }))
          if (!e.response?.data?.success) {
            if (e.response?.data?.error) {
              if (e.response?.data?.error === "Invalid Credential") {
                setStatus("The login detail is incorrect");
              } else {
                setStatus(e.response?.data?.error);
              }
            } else {
              setStatus("Something went wrong!");
            }
          }
        });
    },
  });

  return (
    <Box sx={{ height: "100%" }}>
      <Box
        className="login_background"
      >
        <Box
          className="login_section"
        >
          <div style={{ padding: "50px 20px" }}>
            <Stack spacing={1} sx={{ mb: 3 }} style={{ textAlign: "center" }}>
              <Typography variant="h4">Sign In</Typography>
              <Typography color="text.secondary" variant="body2">
                Unlock the Power of Data: Seamlessly Connect, Analyze, and
                Transform
              </Typography>
            </Stack>

            {formik.errors.submit && (
              <Alert color="error" severity="error" sx={{ mb: 3 }}>
                <Typography color="error" variant="body2">
                  {formik.errors.submit}
                </Typography>
              </Alert>
            )}

            <form noValidate onSubmit={formik.handleSubmit}>
              <Stack spacing={3}>
                <TextField
                  error={!!(formik.touched.email && formik.errors.email)}
                  fullWidth
                  helperText={formik.touched.email && formik.errors.email}
                  placeholder="Email Address"
                  name="email"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="email"
                  value={formik.values.email}
                />
                <TextField
                  error={!!(formik.touched.password && formik.errors.password)}
                  fullWidth
                  helperText={formik.touched.password && formik.errors.password}
                  placeholder="Password"
                  name="password"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="password"
                  value={formik.values.password}
                />
              </Stack>

              <Button
                style={{
                  width: "100%",
                  marginTop: "20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "5px"
                }}
                type="submit"
                variant="primary"
                disabled={
                  formik.isSubmitting ||
                  formik.errors.email ||
                  formik.errors.password
                }
              >
                {
                  loading ? (
                    "Signing In..."
                  ) :
                    "Sign In"
                }
                <CircularProgress size={20} style={{ marginLeft: "10px", color: "#fff" }} hidden={!formik.isSubmitting} />
              </Button>
            </form>
          </div>
        </Box>
      </Box>
    </Box>
  );
};

export default Login;
