import React from "react";
import Select from "react-select";
import SimpleValue from "react-select-simple-value";

const CustomReactSelect = ({
  options,
  value,
  sortOptions = true,
  ...props
}) => (
  <SimpleValue
    options={
      sortOptions
        ? options
            ?.slice(0)
            .sort((a, b) =>
              typeof a.label === "number" && typeof b.label === "number"
                ? a.label - b.label
                : a?.label?.localeCompare(b.label)
            )
        : options
    }
    value={value}
  >
    {(simpleProps) => (
      <Select
        {...simpleProps}
        {...props}
        value={value === "" ? "" : simpleProps.value}
      />
    )}
  </SimpleValue>
);

export default CustomReactSelect;
