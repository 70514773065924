import axios from "axios";

export const HOST_URL = "https://api.databazaar.co/api/v1";

export const getAllDataBank = (payload) => {
  return axios.post(HOST_URL + "/getUploadedProjectDataSheetList", payload);
};

export const getDataProfile = (id) => {
  return axios.get(`${HOST_URL}/getUploadedProjectDataSheet/` + id);
};

export const getProjectById = (payload) => {
  return axios.post(HOST_URL + "/getProjectDataDetails", payload);
};
