import ClearIcon from "@mui/icons-material/Clear";
import { IconButton, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import _ from "lodash";
import { useMemo } from "react";
import GridLayout from "react-grid-layout";
import SettingsIcon from "@mui/icons-material/Settings";

export const rdgPropsConfig = {
  screenWidth: 1900,
  screenHeight: 1700,
  margin: [12, 12],
  cols: 3,
  rowHeight: 180,
  maxRows: 50,
  zoomFactor: 0.4,
};

const IconActionButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",

  borderRadius: "0px",
  backgroundColor: "#00000015",
  "&:hover, &:active": {
    backgroundColor: "#00000025",
  },
}));

const DynamicGridLayout = ({
  layoutWithData,
  containerStyles = {},
  overwriteDefaultGridProps = {},
  width = 0,
  height = 0,
  forViewOnly = false,
  onPutItem = () => { },
  onLayoutChange = () => { },
  splitClassname = true,
  zoomFactor = 0.4,
  onChnageConfig = () => { },
  customLayout = false
}) => {
  const defaultProps = useMemo(
    () => ({
      className: "layout",
      onLayoutChange: (layout) => {
        onLayoutChange(layout);
      },

      width: rdgPropsConfig?.screenWidth * zoomFactor,
      layout: layoutWithData,
      cols: rdgPropsConfig?.cols,
      rowHeight: rdgPropsConfig?.rowHeight * zoomFactor,
      margin: rdgPropsConfig?.margin.map((m) => m * zoomFactor),

      maxRows: rdgPropsConfig?.maxRows,
      ...(forViewOnly && {
        isResizable: false,
        isDroppable: false,
        isDraggable: false,
      }),
      preventCollision: true,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [zoomFactor, layoutWithData]
  );

  const dims = {
    width: width * zoomFactor,
    height: height * zoomFactor,
  };

  const generateDOM = () => {
    return _.map(layoutWithData, (l) => {
      return (
        <div
          key={l.i}
          className={
            splitClassname ? `module-${l.i?.split("-")[0]}` : `module-${l.i}`
          }
          style={{
            border: "1px #111 solid",
            borderRadius: "4px",
            padding: "10px",
          }}
        >
          <Tooltip
            disableInteractive={true}
            arrow
            title="Remove Module From Layout"
          >
            <IconActionButton
              sx={{
                right: 0,
                top: 0,
                height: "30px",
                width: "30px",
              }}
              onMouseDown={(e) => {
                e.stopPropagation();
              }}
              onTouchStart={(e) => {
                e.stopPropagation();
              }}
              onClick={(e) => {
                e.stopPropagation();
                onPutItem(l);
              }}
              size={"small"}
            >
              <ClearIcon />
            </IconActionButton>
          </Tooltip>
          <span
            className={!forViewOnly ? "text" : "text textsmall"}
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {l.label ? l.label : l.i}
          </span>
          <Tooltip
            disableInteractive={true}
            arrow
            title="Change the Module configuration"
          >
            <IconActionButton
              sx={{
                left: 0,
                top: 0,
                height: "30px",
                width: "30px",
              }}
              onMouseDown={(e) => {
                e.stopPropagation();
              }}
              onTouchStart={(e) => {
                e.stopPropagation();
              }}
              onClick={(e) => {
                e.stopPropagation();
                onChnageConfig(l);
              }}
              size={"small"}
            >
              <SettingsIcon />
            </IconActionButton>
          </Tooltip>
        </div>
      );
    });
  };

  return (
    <div
      style={{
        width: customLayout ? "100%" : dims.width,
        height: dims.height,
        margin: "0 auto",
        backgroundColor: "#ddd",
        overflow: "auto",
        ...containerStyles,
      }}
    >
      <GridLayout
        {...defaultProps}
        compactType={null}
        style={{ backgroundColor: "#ddd", position: "relative" }}
        {...{
          className: "layout",
          onLayoutChange: (layout) => {
            onLayoutChange(layout);
          },

          width: rdgPropsConfig?.screenWidth * zoomFactor,
          layout: layoutWithData,
          cols: rdgPropsConfig?.cols,
          rowHeight: rdgPropsConfig?.rowHeight * zoomFactor,
          margin: rdgPropsConfig?.margin.map((m) => m * zoomFactor),

          maxRows: rdgPropsConfig?.maxRows,
          isResizable: false,
          preventCollision: true,
        }}
        isResizable={true}
        autoSize={true}
      >
        {generateDOM()}
      </GridLayout>
    </div>
  );
};

export default DynamicGridLayout;
