import { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  Navigate,
  Route,
  Routes as Switch
} from "react-router-dom";
import AllAlerts from "./AllAlerts";
import { getUserByToken } from "./apis/login";
import BasePage, { ErrorPage } from "./BasePage";
import Layout from "./Layout";
import { actionTypes } from "./store/slice/authRedux";
import { LayoutSplashScreen } from "./utils/SplashScreen";
import Login from "./views/pages/login/Login";
import Logout from "./views/pages/Logout";

export function Routes() {
  const { isAuthorized, landingPage, permissions, authToken } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.isAuthorized != null,
      landingPage: auth.user?.landingPage,
      permissions: auth.permissions,
      authToken: auth.authToken,
    }),
    shallowEqual
  );
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getUserByToken()
      .then((res) => {
        dispatch({
          type: actionTypes.Login,
          payload: {
            data: {
              token: localStorage.getItem("token"),
              user: localStorage.getItem("user"),
            },
          },
        });
      })
      .catch((e) => { })
      .finally(() => {
        setLoading(false);
      });
  }, [authToken, dispatch]);

  if (loading) {
    return <LayoutSplashScreen />;
  }

  return (
    <>
      <Switch>
        {!isAuthorized ? (
          <>
            {/* Redirect any unauthorized access to login */}
            <Route path="/auth/login" element={<Login />} />
            <Route path="*" element={<Navigate to="/auth/login" replace />} />
          </>
        ) : (
          <>
            {/* Redirect /auth/* to home if already authorized */}
            <Route path="/auth/*" element={<Navigate to="/" replace />} />

            {/* Application routes for authorized users */}
            <Route path="/error" element={<ErrorPage />} />
            <Route path="/logout" element={<Logout />} />
            <Route
              path="*"
              element={
                <Layout>
                  <BasePage
                    permissions={permissions}
                    landingPage={landingPage}
                  />
                </Layout>
              }
            />
          </>
        )}
      </Switch>
      <AllAlerts />
    </>
  );
}
