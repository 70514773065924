import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import React from "react";
import { useDispatch } from "react-redux";
import TablePaginationActions from "../TablePagination/TablePaginationActions";

const VisuallyHidden = styled("span")(({ theme }) => {
  return {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  };
});

const TableCustomSelect = ({
  rowData = [],
  selectedCheckbox = [],
  setSelectedCheckbox = () => {},
  columns = [],
  showPagination = true,
  actions,
  page,
  dataCount,
  dataPerPage,
  numCols,
  //-------------sorting--------------
  order = "asc",
  sortOrderBy = "updatedAt",
  handleRequestSort,
  //-------------sorting--------------
}) => {
  const dispatch = useDispatch();

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      return setSelectedCheckbox(rowData);
    }
    return setSelectedCheckbox([]);
  };
  const onSelectHandler = (event, selectedRowData) => {
    if (event.target.checked) {
      return setSelectedCheckbox([...selectedCheckbox, selectedRowData]);
    }
    return setSelectedCheckbox((prev) => {
      return prev.filter((data) => data.id.data !== selectedRowData.id.data);
    });
  };

  return (
    <>
      <TableContainer>
        <Table aria-label="collapsible table" stickyHeader={true}>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  color="success"
                  sx={{
                    "& .MuiSvgIcon-root": { fontSize: 22 },
                  }}
                  indeterminate={
                    selectedCheckbox.length > 0 &&
                    selectedCheckbox.length !== rowData.length
                  }
                  checked={
                    selectedCheckbox.length > 0 &&
                    selectedCheckbox.length === rowData.length
                  }
                  // onChange={onSelectAllClick}
                  inputProps={{
                    "aria-label": "select all desserts",
                  }}
                  onClick={handleSelectAll}
                />
              </TableCell>
              {columns.map((c, i) => (
                <TableCell
                  key={i}
                  align={c.align}
                  sx={{
                    fontSize: "1.2rem",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    ...c.styles,
                  }}
                  sortDirection={sortOrderBy === c.id ? order : false}
                >
                  {c.sort ? (
                    <TableSortLabel
                      active={sortOrderBy === c.id}
                      direction={sortOrderBy === c.id ? order : "asc"}
                      onClick={(e) =>
                        handleRequestSort(
                          c.id !== "patientName" ? c.id : "firstName",
                          sortOrderBy,
                          order
                        )
                      }
                    >
                      {c.label}
                      {sortOrderBy === c.id ? (
                        <VisuallyHidden>
                          {order === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </VisuallyHidden>
                      ) : null}
                    </TableSortLabel>
                  ) : (
                    <>{c.label}</>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              "& > *:nth-of-type(even)": {
                backgroundColor: "#aaaaaa40",
              },
            }}
          >
            {rowData?.length > 0 ? (
              rowData.map((row, ii) => (
                <TableRow key={ii}>
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="success"
                      key={row.id.data}
                      id={row.id.data}
                      sx={{ "& .MuiSvgIcon-root": { fontSize: 22 } }}
                      // indeterminate={numSelected > 0 && numSelected < rowCount}
                      checked={selectedCheckbox.some(
                        (d) => d.id.data === row.id.data
                      )}
                      onChange={(e) => onSelectHandler(e, row)}
                      inputProps={{
                        "aria-label": "select desserts",
                      }}
                    />
                  </TableCell>
                  {Object.values(row).map(
                    (single, j) =>
                      single.display && (
                        <TableCell
                          style={{ ...single.styles }}
                          align={single.align}
                          key={j + "" + single.label}
                          size="small"
                          sx={{
                            fontSize: "1rem",
                          }}
                          rowSpan={single.rowSpan}
                          colSpan={single.colSpan}
                        >
                          {single.clickable ? (
                            <>
                              <a href={single.linkUrl} target="blank">
                                {single.data}
                              </a>
                              {single.copyHandler}
                            </>
                          ) : (
                            single.data
                          )}
                        </TableCell>
                      )
                  )}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length + 1}
                  style={{
                    fontSize: "1.1rem",
                    textAlign: "center",
                    height: "100px",
                    padding: "6px",
                  }}
                >
                  <Box
                    sx={{
                      border: (theme) =>
                        "2px solid " + theme.palette.error.main,
                      borderRadius: (theme) => `${theme.shape.borderRadius}px`,
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontWeight: 500,
                      color: (theme) => theme.palette.error.main,
                    }}
                  >
                    No Data Available
                  </Box>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {showPagination && (
        <table>
          <tfoot>
            <tr>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                colSpan={numCols + 1}
                count={dataCount}
                rowsPerPage={dataPerPage}
                page={page}
                SelectProps={{
                  inputProps: { "aria-label": "rows per page" },
                  native: true,
                }}
                onPageChange={(event, newPage) => {
                  dispatch(
                    actions.setPageConfigData({
                      type: "SET_PAGE",
                      data: newPage,
                    })
                  );
                  // setPage(newPage);
                }}
                onRowsPerPageChange={(event) => {
                  dispatch(
                    actions.setPageConfigData({
                      type: "SET_DATA_PER_PAGE",
                      data: parseInt(event.target.value, 10),
                    })
                  );
                  dispatch(
                    actions.setPageConfigData({ type: "SET_PAGE", data: 0 })
                  );
                }}
                ActionsComponent={TablePaginationActions}
              />
            </tr>
          </tfoot>
        </table>
      )}
    </>
  );
};

export default TableCustomSelect;
